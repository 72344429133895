import React, { useState } from "react";
import CustomModal from "../../components/com/modal/Modal";
import CompanyContactForm from "./CompanyContactForm";
import { Button, Col, Icon, List, Modal, Row } from "antd";

const { confirm } = Modal;

const style = {
	button: {
		width: 40,
		height: 40,
		float: "right",
	},
	icon: {
		padding: 0,
	},
};
const renderItemList = (item) => {
	let full_name = `${item.first_name} ${item.last_name}`;
	let { phone_number, deparment = {}, onAction, onRemove } = item;
	return (
		<List.Item
			style={{
				borderBottom: "1px solid #cccccc75",
				marginBottom: 0,
			}}
		>
			<Row
				type="flex"
				justify="start"
				align="middle"
				gutter={8}
				style={{
					padding: "0px 0px 8px 0px",
				}}
			>
				<Col span={6} align="left">
					<div className="col-contact left">
						<Icon type="user" /> {full_name}
					</div>
				</Col>
				<Col span={6} align="left">
					<div className="col-contact left">{deparment.name}</div>
				</Col>
				<Col span={6} align="left">
					<div className="col-contact left">
						<Icon type="phone" /> {phone_number}
					</div>
				</Col>
				<Col span={6} align="center">
					<div className="col-contact center">
						<Row
							type="flex"
							gutter={16}
							align="middle"
							justify="center"
							style={{
								marginRight: 20,
							}}
						>
							<Col align="center">
								<Button
									onClick={() => onAction("edit", item)}
									style={style.button}
									shape="circle"
									key="list-loadmore-edit"
									icon="edit"
								/>
							</Col>
							<Col align="center">
								<Button
									onClick={() => onAction("delete", item)}
									style={style.button}
									shape="circle"
									key="list-loadmore-more"
									icon="delete"
								/>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</List.Item>
	);
};
const ContactsList = (props) => {
	let { loading, data = [], onRemove } = props;
	const [visible, setVisible] = useState(false);
	const [selected, setSelected] = useState({});

	const onAction = (action, record) => {
		switch (action) {
			case "edit":
				setVisible(true);
				setSelected(record);
				break;
			case "delete":
				confirm({
					title: "Desea eliminar éste contácto?",
					content: "Al hacer click en el botón OK, se eliminará el contácto.",
					onOk() {
						if (onRemove) onRemove(record.id);
					},
					onCancel() {},
				});
				break;
			default:
				break;
		}
	};
	const handleSubmit = (err, data) => {
		if (props.onSubmit)
			props.onSubmit(err, data).then((response) => {
				setVisible(false);
				/*  setSelected(response); */
			});
	};

	data = data.map((item) => {
		item["onAction"] = onAction;
		return item;
	});
	return (
		<div className="contact-container">
			<Row className="perfil-toolbar" type="flex" gutter={16}>
				<Col span={12} align="start">
					<h2>Lista de Contactos</h2>
				</Col>
				<Col span={12}>
					<Button
						style={{
							float: "right",
						}}
						onClick={() => {
							setVisible(true);
						}}
						size="large"
						className="btn-green"
					>
						<Icon type="plus" />{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							CREAR NUEVO
						</span>
					</Button>
				</Col>
			</Row>
			<Row type="flex" align="middle" justify="center">
				<List
					itemLayout="vertical"
					loading={loading}
					grid={{
						column: 1,
						gutter: 16,
					}}
					dataSource={data}
					renderItem={renderItemList}
				/>
				{visible && (
					<CustomModal
						closable
						title={
							<h2>
								{selected.id ? (
									<span>
										<Icon type="edit" /> Editar Contacto
									</span>
								) : (
									<span>
										<Icon type="user" /> Crear Contacto
									</span>
								)}
							</h2>
						}
						className="modal-company"
						visible={visible}
						footer={null}
						onCancel={() => {
							setVisible(false);
							setSelected({});
						}}
					>
						<div>
							<CompanyContactForm {...selected} onSubmit={handleSubmit} />
						</div>
					</CustomModal>
				)}
			</Row>
		</div>
	);
};

export default ContactsList;
