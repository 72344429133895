const UIReducer = (state = {}, action = {}) => {
    if (action.type === 'SET_MENU_HEADER') {
        return {
            ...state,
            menus: action.menus
        }
    }

    return state
}

export default UIReducer