import React, { Component, useEffect, useState } from "react";
import { connectMenu } from "react-instantsearch-dom";
import { Select } from "antd";
import _ from "lodash";

const { Option } = Select;

const Menu = (props) => {
	let {
		items,
		showArrow = false,
		showSearch = true,
		allowClear = true,
		filterOption = false,
		defaultActiveFirstOption = false,
		placeholder,
		label,
		type,
		defaultValue,
	} = props;

	const [choices, setChoices] = useState(items || []);
	useEffect(() => {
		if (defaultValue) props.refine(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		let { sort } = props;
		if (sort === "ASC" && items.length) {
			let choices = _.sortBy(items, [
				function (it) {
					return it["label"];
				},
			]);
			setChoices(choices);
		} else if (items.length) {
			setChoices(items);
		}
	}, [items]);
	return (
		<Select
			showSearch={showSearch}
			label={label}
			type={type}
			defaultActiveFirstOption={defaultActiveFirstOption}
			showArrow={showArrow}
			placeholder={placeholder}
			filterOption={filterOption}
			notFoundContent={null}
			allowClear={allowClear}
			defaultValue={defaultValue}
			onSearch={(value) => {
				props.searchForItems(value);
			}}
			onChange={(value) => {
				props.refine(value);
			}}
			/*
  onChange={onChange}
  onFocus={onFocus}
  onBlur={onBlur}
  onSearch={onSearch}
  filterOption={(input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
  */
		>
			{choices.map((item, index) => {
				return (
					<Option key={item.value} value={item.value}>
						{item.label}
					</Option>
				);
			})}
		</Select>
	);
};
export default class MenuSelect extends Component {
	render() {
		let { limit = 5000000 } = this.props;
		let CustomMenu = connectMenu(Menu);
		return (
			<CustomMenu
				type={this.props.type}
				limit={limit}
				searchable={true}
				{...this.props}
			/>
		);
	}
}
