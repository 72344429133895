import React, { useEffect, useState } from "react";
import CustomModal from "../modal/Modal";

import Form from "../form/AdvancedForm";
import Select from "../select/SelectField";
import FileUploader from "../form/S3Uploader";
import { ToggleField } from "../index";
import Map from "../map/MapBox";
/*  */
import { Button, Col, Icon, Input, List, message, Modal, Row } from "antd";

import "./crud.css";

const defaultActions = [
	{
		action: "edit",
		icon: "edit",
	},
	{
		action: "delete",
		icon: "delete",
	},
];
const { confirm } = Modal;
const style = {
	button: {
		width: 40,
		height: 40,
		float: "right",
	},
	icon: {
		padding: 0,
	},
	flex: {
		width: "100%",
	},
};
const Store = (props) => {
	let { model } = props;
	let { service, baseParams = {}, id } = model;
	const [data, setData] = useState(model.data || []);
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	if (typeof service === "function" && !loaded) {
		setLoading(true);
		if (id)
			return service(id, baseParams).then((response) => {
				let { data } = response;
				setData(data);
				setLoading(false);
			});
		service(baseParams).then((response) => {
			let { data } = response;
			setData(data);
			setLoading(false);
		});
		setLoaded(true);
	}
	return <Select {...props} dataSource={data} />;
};
const Factory = (props) => {
	let { xtype } = props;
	switch (xtype) {
		case "selectfield":
			return <Store {...props} />;
			break;
		case "switch":
			return <ToggleField {...props} />;
			break;
		case "fileuploader":
			return <FileUploader {...props} />;
			break;
		case "textarea":
			return <Input.TextArea {...props} />;
			break;
		case "map":
			return (
				<Row {...props} type="flex" justify="center" align="middle">
					<Col span={24}>
						<div
							style={{
								width: "100%",
								height: 200,
								marginBottom: 60,
							}}
						>
							{props.title && <h2>{props.title}</h2>}
							{
								<Map
									onClick={(eOpts, e) => {
										let { lngLat } = e;
										if (props.onChange) props.onChange(lngLat);
									}}
									zoom={20}
									style={{
										width: "100%",
										height: "100%",
									}}
								/>
							}
						</div>
					</Col>
				</Row>
			);
			break;
		default:
			return <Input size={props.size || "large"} {...props} />;
			break;
	}
};

const CRUD = (props) => {
	let { loading, model = {} } = props;
	props = { ...props, ...model };
	let {
		title,
		name = "",
		columns = [],
		service,
		showHeaders = true,
		allowCreate = true,
		actions = [],
		displayMessageError = false,
		allRequired,
	} = props;

	actions =
		actions.length > 0
			? actions.filter((item) => {
				if (item) return true;
			})
			: defaultActions;
	const [visible, setVisible] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [items, setItems] = useState([]);
	const [data, setData] = useState(model.data || []);
	const [filters, setFilters] = useState({});
	const [record, setRecord] = useState({});
	const onRemove = (id) => {
		if (service) {
			return service
				.remove(id)
				.then((response) => {
					message.info("Registro eliminado.");
					loadData(filters);
				})
				.catch((err) => message.error(err.message));
		}
	};
	const onAction = (action, record) => {
		switch (action) {
			case "edit":
				setRecord(record);
				setItems(getFields(record));
				setVisible(true);
				break;
			case "delete":
				confirm({
					title: `Desea eliminar éste ${name ? name : "registro"}?`,
					content: `Al hacer click en el botón OK, se eliminará el ${name ? name : "registro"
						}.`,
					onOk() {
						if (onRemove) onRemove(record.id);
					},
					onCancel() { },
				});
				break;
			default:
				if (props.onAction) props.onAction(action, record);
				break;
		}
	};
	const Submit = (data) => {
		return new Promise((resolve, reject) => {
			if (service) {
				let { id } = data;
				if (id) {
					return service.patch(id, data).then(resolve).catch(reject);
				}
				return service.create(data).then(resolve).catch(reject);
			}
		});
	};
	const handleSubmit = (err, data) => {

		if (err) {
			if (!displayMessageError) return;
			let msg = [];
			err = Object.values(err).map((error) => {
				error.errors.map((item) => {
					msg.push(item["message"]);
				});
			});
			return message.error(
				msg.map((error) => {
					return (
						<span
							style={{
								textAlign: "left",
							}}
						>
							{error}
							<br />
						</span>
					);
				})
			);
		}

		setSubmitting(true);
		return new Promise((resolve, reject) => {
			console.log("hola5", data)
			Submit(data)
				.then((response) => {
					message.info(
						`Registro ${!data.id ? "creado" : "actualizado"} con éxito`
					);
					loadData(filters);
					setVisible(false);
					setSubmitting(false);
					if (props.onSubmit) {
						props.onSubmit(response);
					}
					resolve(response);
				})
				.catch((err) => {
					message.error(err.message || JSON.stringify(err));
					setSubmitting(false);
					reject(err);
				});
		});
	};
	const loadData = (params = {}) => {
		if (service)
			service.find(params).then((response) => {
				let { data } = response;
				data = data.map((item) => {
					item["onAction"] = onAction;
					return item;
				});
				setData(data);
			});
	};
	const renderHeader = (columns) => {
		return (
			<Row
				type="flex"
				justify="center"
				align="middle"
				gutter={8}
				style={{
					padding: "0px 0px 8px 0px",
				}}
			>
				{columns.map((col, index) => {
					col.span = col.span || Math.round(24 / (columns.length + 1));
					return (
						<Col
							key={index}
							span={col.span}
							align={col.align ? col.align : "left"}
						>
							<div className={`col-crud ${col.align ? col.align : "left"}`}>
								<h3>{col.text}</h3>
							</div>
						</Col>
					);
				})}
				{actions.length > 0 && (
					<Col
						style={{
							margin: "0px auto",
						}}
					>
						<div className={`col-crud center`}>
							<h3>Acciones</h3>
						</div>
					</Col>
				)}
			</Row>
		);
	};
	const renderItemList = ({ item = {}, columns = [], actions }) => {
		let { onAction } = item;
		actions = actions ? actions : defaultActions;
		return (
			<List.Item
				style={{
					borderBottom: "1px solid #cccccc75",
					marginBottom: 0,
					paddingTop: 8,
				}}
			>
				<Row
					type="flex"
					justify="center"
					align="middle"
					gutter={8}
					style={{
						padding: "0px 0px 8px 0px",
					}}
				>
					{columns.map((col, index) => {
						col.span = col.span || Math.round(24 / (columns.length + 1));
						return (
							<Col
								key={index}
								span={col.span}
								align={col.align ? col.align : "left"}
							>
								<div className={`col-crud ${col.align ? col.align : "left"}`}>
									{!col.render ? item[col.dataIndex] : col.render(item)}
								</div>
							</Col>
						);
					})}
					{actions.length > 0 && (
						<Col align="center" className="action-container">
							<div className={`col-crud center`}>
								<Row
									type="flex"
									gutter={5}
									align="middle"
									justify="center"
									style={{
										marginRight: 0,
									}}
								>
									{actions
										.filter((item) => {
											if (item) return true;
										})
										.map((el, index) => {
											let { action, icon, key } = el;
											return (
												<Col align="center" key={key || index}>
													<Button
														onClick={() => onAction(action, item)}
														style={el.style || style.button}
														shape="circle"
														key={key || index}
														icon={icon}
													/>
												</Col>
											);
										})}
								</Row>
							</div>
						</Col>
					)}
				</Row>
			</List.Item>
		);
	};
	const getFields = (record = {}) => {
		let { fields } = props;
		let items = [];
		let primaryKey = props["primaryKey"] || "id";
		let isPrimary = false;
		if (fields)
			fields.forEach((field, index) => {
				let type = field.type || "textfield";
				field[type] = type;
				if (field["name"] === primaryKey) isPrimary = true;
				/* Reset Values */
				if (field["initialValue"]) delete field["initialValue"];
				/* Reset Values */
				if (field["name"] in record)
					field["initialValue"] = record[field["name"]];
				items.push(<Factory key={index} {...field} />);
			});
		if (!isPrimary) {
			let primary = {
				name: primaryKey,
				type: "hidden",
			};
			items.push(<Factory {...primary} />);
		}
		return items;
	};
	useEffect(() => {
		if (data.length > 0)
			setData(
				data.map((item) => {
					item["onAction"] = onAction;
					return item;
				})
			);
		loadData(props.params);
		setItems(getFields(record));
		return () => {
			setRecord({});
			setItems([]);
		};
	}, []);
	return (
		<div className="crud-container">
			<Row className="perfil-toolbar" type="flex" gutter={16}>
				<Col span={12} align="start">
					<h2>{title || `Listado ${name ? " de " + name : ""}`}</h2>
				</Col>
				{allowCreate && (
					<Col span={12}>
						<Button
							style={{
								float: "right",
							}}
							onClick={() => {
								setRecord({});
								setItems(getFields(record));
								setVisible(true);
							}}
							size="large"
							className="btn-green"
						>
							<Icon type="plus" />{" "}
							<span
								style={{
									fontWeight: "bold",
								}}
							>
								CREAR NUEVO
							</span>
						</Button>
					</Col>
				)}
			</Row>
			<Row type="flex" align="middle" justify="center">
				{
					<List
						className="crud-list"
						itemLayout="vertical"
						loading={loading}
						grid={{
							column: 1,
							gutter: 16,
						}}
						style={{
							width: "100%",
						}}
						dataSource={data}
						renderItem={(item) => renderItemList({ item, columns, actions })}
						header={showHeaders ? renderHeader(columns) : null}
					/>
				}
				{visible && (
					<CustomModal
						closable
						title={
							<h2>
								{record.id ? (
									<span>
										<Icon type="edit" /> {`Editar ${name}`}
									</span>
								) : (
									<span>
										<Icon type="user" /> {`Crear ${name}`}
									</span>
								)}
							</h2>
						}
						className="modal-company"
						visible={visible}
						footer={null}
						onCancel={() => {
							setRecord({});
							setVisible(false);
						}}
					>
						<div>
							<Form
								allRequired={allRequired}
								onSubmit={handleSubmit}
								submitting={submitting}
							>
								<div>{items}</div>
							</Form>
						</div>
					</CustomModal>
				)}
			</Row>
		</div>
	);
};

export default CRUD;
