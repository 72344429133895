import React from "react";
import CardItem from "./CardItem";
import { List } from "antd";
import { getUserInfo } from "../../services"; 

import "./cardlist.css";

const CardList = (props) => {


	const defaultGrid = props.grid || {
		gutter: 16,
		column: props.column || 4,
		xs: 1,
		sm: 2,
		md: 2,
		lg: 4,
		xl: 4,
		xxl: 4,
	};
	let renderItem =
		props.renderItem ||
		function (record) {
			return (
				<List.Item>
					<CardItem {...record}/>
				</List.Item>
			);
		};
	return (
		<List
			className={props.className || "home-card-list"}
			itemLayout={props.itemLayout || "horizontal"}
			grid={defaultGrid}
			dataSource={props.dataSource}
			renderItem={renderItem}
		></List>
	);
};
export default CardList;
