import React, { Component } from "react";
import { connectMenu } from "react-instantsearch-dom";
import { Radio } from "antd";

const Group = (props) => {
	let {
		showArrow = false,
		showSearch = true,
		allowClear = true,
		filterOption = false,
		defaultActiveFirstOption = false,
		placeholder,
	} = props;
	return (
		<Radio.Group
			showSearch={showSearch}
			defaultActiveFirstOption={defaultActiveFirstOption}
			showArrow={showArrow}
			placeholder={placeholder}
			filterOption={filterOption}
			notFoundContent={null}
			allowClear={allowClear}
			onChange={(e) => {
				let { value } = e.target;
				props.refine(value);
			}}
		>
			{props.children}
		</Radio.Group>
	);
};
export default class MenuSelect extends Component {
	render() {
		let { limit = 4 } = this.props;
		let CustomGroup = connectMenu(Group);
		return <CustomGroup limit={limit} searchable={true} {...this.props} />;
	}
}
