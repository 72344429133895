import React from "react";
import  ReactApexChart  from "react-apexcharts"


class Lienar extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          name: "sales",
          data: this.props.serie || [2, 6, 7, 9, 12]
        }],
        // series:{
        //   name:"datos",
        //   data:this.state.series || [2, 6, 7, 9, 12]
        // },

        options: {
          chart: {
            type: 'bar',
            height: 300,
          },
          xaxis: {
            labels:{
                rotate:0,
            },
            categories: [
                ["Buscador" ,"de Lotes"], 
                ["Actividad" ,"edificadora"], 
                "Camacol Compra",
                ["Directorio", "de afiliados"],
                ["Trámites para", "el constructor"]
                ]
            },
            title: {
                text:this.props.title,
            },
            group: {
              style: {
                fontSize: '8px',
                fontWeight: 700
              },
              groups: [
                { title: '2019', cols: 4 },
                { title: '2020', cols: 4 }
              ]
            }
          },
      };
    }

    render() {
      return (
        <div>
          <div id="chart" style={{ background:"white", padding:"1rem", borderRadius:"0.5rem"}}>
            <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={270}  />
          </div>
          <div id="html-dist"></div>
        </div>
      );
    }
  }

//   const domContainer = document.querySelector('#app');
//   ReactDOM.render(React.createElement(ApexChart), domContainer);

export default Lienar; 