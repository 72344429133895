import React, { useEffect, useState } from "react";
import { Select } from "antd";
import debounce from "lodash/debounce";

const { Option } = Select;
const SelectField = (props) => {
	let [initialValue, setInitialValue] = useState(props.initialValue);
	const [initialized, setInitialized] = useState(false);
	const [value, setValue] = useState();
	let {
		onSearch = (value) => {},
		onChange,
		placeholder,
		style,
		dataSource = [],
		displayField = "name",
		valueField = "id",
		label,
		name,
		mode,
		size = "large",
		initial,
		loading = false,
		allowClear = true,
		allowSelectAll = false,
		selectAllText = "Seleccionar Todos",
		defaultSelectValue = "all",
	} = props;

	const handleChange = (name, value) => {
		setValue(value);
		onChange(name, value);
	};
	const handleOnChange = debounce(handleChange, 500);
	const handleOnSearch = debounce(onSearch, 500);

	const Search = (input, option) => {
		let children = option.props.children || "";
		return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};
	const handleFilter = debounce(Search, 500);

	useEffect(() => {
		if (!initialized) setInitialized(dataSource.length > 0);
		if (initialized) {
		}
		return () => {};
	}, [dataSource]);
	return (
		<>
			{dataSource.length > 0 && (
				<Select
					dropdownStyle={{ minWidth: 350 }}
					name={name}
					size={size}
					allowClear={allowClear}
					mode={mode}
					style={style || { width: 200 }}
					showSearch
					label={label}
					loading={loading || !initialized}
					placeholder={placeholder}
					defaultValue={props.initialValue}
					optionFilterProp="children"
					onSearch={handleOnSearch}
					onChange={handleOnChange}
					filterOption={(input, option) => {
						let children = option.props.children || "";
						if (typeof children !== "string") {
							children = String(children);
						}
						return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
					}}
				>
					{allowSelectAll && (
						<Option key={-1} value={defaultSelectValue}>
							{selectAllText}
						</Option>
					)}
					{dataSource.map((item, index) => (
						<Option key={index} value={item[valueField]}>
							{item[displayField]}
						</Option>
					))}
				</Select>
			)}
		</>
	);
};

export default SelectField;
