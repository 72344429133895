import React, { Component } from "react";
import { Col, Icon, message, Row, Tabs } from "antd";
import Layout from "../../layouts/main_layout/mainLayout";
import { CRUD } from "../../components/com";
import {
	companiescontacts,
	companyService,
	createContact,
	editContact,
	getCompanyInfo,
	getUserInfo,
	getUserRoles,
	removeContact,
} from "../../services";
import { company_service, user_service } from "../../services/services";
import CompanyForm from "./CompanyForm";
import ContactsList from "./ContactsList";
import "./perfil.css";

const model = {
	name: "usuario",
	title: "Listado de Usuarios",
	service: user_service,
	/* showHeaders:false, */
	fields: [
		{
			name: "id",
			type: "hidden",
		},
		{
			name: "first_name",
			label: "Nombres",
			placeholder: "Nombres",
			validations: [
				{
					required: true,
					message: "Debe ingresar sus nombres",
				},
			],
		},
		{
			name: "last_name",
			label: "Apellidos",
			placeholder: "Apellidos",
			validations: [
				{
					required: true,
					message: "Debe ingresar sus apellidos",
				},
			],
		},
		{
			name: "phone_number",
			label: "Teléfono",
			placeholder: "Teléfono",
			validations: [
				{
					required: true,
					message: "Debe ingresar un número telefónico",
				},
			],
		},
		{
			xtype: "selectfield",
			name: "company_role_id",
			label: "Rol",
			placeholder: "Perfil de usuario",
			/*  style: {
		width: "100%"
	  }, */
			validations: [
				{
					required: true,
					message: "Debe seleccionar un perfil de usuario",
				},
			],
			model: {
				service: getUserRoles,
			},
		},
		{
			name: "email",
			label: "E-Mail",
			placeholder: "E-Mail",
			type: "email",
			validations: [
				{
					required: true,
					message: "Debe ingresar una correo electrónico",
				},
			],
		},
		{
			name: "password",
			label: "Contraseña",
			type: "password",
			validations: [
				{
					required: true,
					message: "Debe ingresar una Contraseña",
				},
			],
			placeholder: "Contraseña",
		},
		/* {
	  xtype: "selectfield",
	  name: "active",
	  label: "active",
	  placeholder: "active",
	  model: {
		data: [
		  {
			name: "Activo",
			id: "true"
		  },
		  {
			name: "Inactivo",
			id: "false"
		  }
		]
	  }
	}, */
		{
			xtype: "switch",
			name: "active",
			label: "Activar/Desactivar",
			placeholder: "Estado",
		},
	],
	columns: [
		{
			text: "Nombre Apellido",
			dataIndex: "first_name",
			render: (item) => (
				<span>
					<Icon type="user" /> {`${item.first_name} ${item.last_name || ""}`}
				</span>
			),
		},
		{
			text: "Email",
			dataIndex: "email",
			align: "left",
			render: (item) => (
				<span>
					<Icon type="user" /> {item.email}
				</span>
			),
		},
	],
	data: [
		{
			first_name: "Juan",
			last_name: "Polo",
			email: "okiloco2@gmail.com",
			company_role_id: 1,
		},
		{
			first_name: "Pepito",
			last_name: "Perez",
			email: "okiloco2@gmail.com",
			company_role_id: 1,
		},
		{
			first_name: "Jose",
			last_name: "Mendes",
			email: "okiloco2@gmail.com",
			company_role_id: 1,
		},
	],
};

const { TabPane } = Tabs;
class Perfil extends Component {
	state = {
		loading: false,
		loading_contacts: true,
		company: {},
		user: {},
	};
	componentWillMount() {
		this.getProjectInfo();
		this.getContacts();
		if (localStorage.getItem("user"))
			this.setState({
				user: JSON.parse(localStorage.getItem("user")),
			});
	}
	getProjectInfo = () => {
		return getUserInfo().then((response) => {
			let { company } = response;
			if (company) this.getCompay(company.id);
		});
	};
	getCompay = (id) => {
		this.setState({
			loading: true,
		});
		return getCompanyInfo(id).then((data) => {

			this.setState({
				company: data,
				loading: false,
			});
		});
	};
	handleSubmit = (err, data) => {
		if (err) return message.error(err.message);
		companyService(data)
			.then((data) => {
				message.info("Registro actualizado con éxito");
				this.setState({
					company: data,
				});
			})
			.catch((err) => message.error(err.message));
	};
	getContacts = () => {
		let { company } = this.state;
		companiescontacts(company.id).then((response) => {
			let { data } = response;
			this.setState({
				contacts: data,
				loading_contacts: false,
			});
		});
	};
	handleRemove = (id) => {
		const self = this;
		removeContact(id)
			.then((response) => {
				message.info("Contácto eliminado");
				self.getContacts();
			})
			.catch((err) => message.error(err.message));
		console.log("company", this.state.company)
	};
	handleContactCompanySubmit = (err, data) => {
		if (err) return message.error(err);
		const self = this;
		let { id } = data;
		if (id) {
			return editContact(id, data)
				.then((response) => {
					message.info("Contácto actualizado con éxito");
					self.getContacts();
				})
				.catch((err) => message.error(err.message));
		}
		return createContact(data)
			.then((response) => {
				message.info("Contácto creado con éxito");
				self.getContacts();
			})
			.catch((err) => message.error(err.message));
	};
	onDelete = () => {
		company_service
			.patch(this.state.company.id, { logo: null })
		this.setState({
			company: { ...this.state.company, logo: null },
		});
	};
	render() {
		let { company, contacts, loading, loading_contacts, user } = this.state;
		let { company_role_id } = user;
		return (
			<Layout className="afliliados-layout">
				<Row type="flex" gutter={8} align="middle">
					<Col
						span={24}
						style={{
							minHeight: "1000vh",
						}}
					>
						<div className="perfil-container">
							<Tabs defaultActiveKey="1" /* type="card" */>
								<TabPane
									tab={
										<h2
											style={{
												fontWeight: "500",
											}}
										>
											INFORMACIÓN GENERAL
										</h2>
									}
									key="1"
								>
									<div
										style={{
											background: "#fff",
											padding: 10,
											borderRadius: "8px",
											boxShadow: "1px 1px 4px 4px #cccccc30",
											margin: "8px",
										}}
									>
										{
											<CompanyForm
												loading={loading}
												data={company}
												onSubmit={this.handleSubmit}
												onDelete={this.onDelete}
											/>
										}
									</div>
								</TabPane>
								<TabPane
									tab={
										<h2
											style={{
												fontWeight: "500",
											}}
										>
											CONTACTOS
										</h2>
									}
									key="2"
								>
									<ContactsList
										data={contacts}
										loading={loading_contacts}
										onRemove={this.handleRemove}
										onSubmit={this.handleContactCompanySubmit}
									/>
								</TabPane>
								<TabPane
									tab={
										<h2
											style={{
												fontWeight: "500",
											}}
										>
											USUARIOS
										</h2>
									}
									key="3"
								>
									<div>
										<CRUD
											allowCreate={true}
											model={model}
											actions={[
												company_role_id === 1 && {
													action: "edit",
													icon: "edit",
												},
												company_role_id === 1 && {
													action: "delete",
													icon: "delete",
												},
											]}
										/>
									</div>
								</TabPane>
							</Tabs>
						</div>
					</Col>
				</Row>
			</Layout>
		);
	}
}
export default Perfil;
