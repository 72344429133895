import React, { Component } from "react";
import Layout from "../../layouts/main_layout/mainLayout";
import "./afiliados.css";
import { Hits, Instant } from "../../components/algolia";
import { Avatar, Card, Col, Icon, List, PageHeader, Row } from "antd";
import Form from "../../components/company/CompanyForm";
import { Modal } from "../../components/com";
import { companiescontacts } from "../../services";
import { NO_IMAGE, S3_INSTANCE_CAMACOL } from "../../utils/Constants";

const { Meta } = Card;
const CardItem = (props) => (
	<List.Item onClick={props.onClick}>
		<Card
			style={{
				height: 180,
			}}
			bordered={false}
			className="card-item card-item-afiliados"
			cover={
				<Row
					type="flex"
					justify="center"
					align="middle"
					className="cover-container"
				>
					<Col span={24}>
						<div className="company-logo-container">
							<img
								className="company-logo"
								src={
									props.logo ? `${S3_INSTANCE_CAMACOL}${props.logo}` : NO_IMAGE
								}
							/>
						</div>
						<div>
							<div className="afiliado-title-container">
								<h2>{props.name}</h2>
							</div>
						</div>
					</Col>
				</Row>
			}
			key={props.name}
			hoverable
			/* style={{ maxWidth: 240, display: "inline-block" }} */
		>
			{/* <Meta
        title={
          <div className="afiliado-title-container">
            <h2>{props.name}</h2>
          </div>
        }
      /> */}
		</Card>
	</List.Item>
);

const renderItemList = (item) => {
	let full_name = `${item.first_name} ${item.last_name}`;
	let {
		phone_number,
		mobile_phone_number,
		email,
		deparment = {},
		contact_position = {},
	} = item;
	return (
		<List.Item
			style={{
				borderBottom: "1px solid #cccccc75",
			}}
		>
			<Row
				type="flex"
				justify="start"
				gutter={8}
				style={{
					padding: "0px 0px 8px 0px",
				}}
			>
				<Col span={6} align="left">
					<div className="col-contact left">
						<Icon type="user" /> {full_name}
					</div>
				</Col>
				<Col span={6} align="left">
					<div className="col-contact left">{`${deparment.name} ${
						contact_position.name ? " - " + contact_position.name : ""
					}`}</div>
				</Col>
				<Col span={6} align="left">
					<div className="col-contact left">{email}</div>
				</Col>
				<Col span={6} align="left">
					{phone_number && (
						<div className="col-contact left">
							<Icon type="phone" /> {phone_number}
						</div>
					)}
					{mobile_phone_number && (
						<div className="col-contact left">
							<Icon type="phone" /> {mobile_phone_number}
						</div>
					)}
				</Col>
			</Row>
		</List.Item>
	);
};
class Afiliados extends Component {
	state = {
		visible: false,
		loading_contacts: false,
		current: {},
		contacts: [],
	};
	handleClick = (hit) => {
		this.setState({
			loading_contacts: true,
		});
		companiescontacts(hit.id).then((response) => {
			let { data } = response;
			this.setState({
				contacts: data,
				loading_contacts: false,
			});
		});
		this.setState({
			visible: true,
			current: hit,
		});
	};
	render() {
		let { visible, current, contacts, loading_contacts } = this.state;
		return (
			<Layout className="afliliados-layout">
				<Instant>
					<Row gutter={16} type="flex" justify="center" align="top">
						<Col className="afiliados-left" xs={24} md={12} lg={8} xl={6}>
							<Form autoSubmit={false} />
						</Col>
						<Col className="afiliados-right" xl={18} md={12} lg={16} xs={24}>
							<PageHeader
								title={
									<h2 className="title-compra">
										<Icon type="dollar" /> Directorio de{" "}
										<span className="text-primary">afiliados</span>
									</h2>
								}
							/>
							<Hits
								itemLayout="horizontal"
								grid={{
									column: 4,
									gutter: 16,
									xs: 1,
									md: 2,
									lg: 2,
									xl: 4,
								}}
								renderItem={(hit) => (
									<CardItem {...hit} onClick={() => this.handleClick(hit)} />
								)}
							/>
						</Col>
					</Row>
				</Instant>
				<Modal
					width={800}
					className="modal-company"
					visible={visible}
					footer={null}
					onCancel={() => {
						this.setState({
							visible: false,
						});
					}}
					title={
						<Row type="flex" justify="space-around" align="middle" gutter={16}>
							<Col span={6}>
								<div className="avatar-company">
									<Avatar
										style={{
											width: "150px",
											height: "100%",
											margin: 10,
											padding: 10,
											backgroundColor: "#fafafa",
										}}
										size="large"
										shape="square"
										src={`${S3_INSTANCE_CAMACOL}${current.logo}`}
									/>
								</div>
							</Col>
							<Col span={18}>
								<div
									style={{
										margin: "10px 4px",
									}}
								>
									{current.name && <h2>{current.name}</h2>}
									{current.sementations &&
										current.sementations.map(({ segmentation }) => {
											let record = Array.isArray(segmentation)
												? segmentation[0]
												: segmentation;
											return (
												<span
													style={{
														margin: "10px 4px",
														boxShadow: "rgb(204 204 204 / 18%) 3px 3px 3px",
														borderRadius: 20,
														color: "#5d5d5d",
														border: "1px solid #ccc6",
														padding: "4px 8px",
													}}
												>
													{record && record.name}
												</span>
											);
										})}
								</div>
								<div>
									{current.mobile_phone_number && (
										<div>
											<Icon type="phone" /> {current.mobile_phone_number}
										</div>
									)}
									{current.email && (
										<div>
											<Icon type="mail" /> {current.email}
										</div>
									)}
									{current.address && <div>{current.address}</div>}
								</div>
							</Col>
						</Row>
					}
				>
					<div>
						{
							<List
								itemLayout="vertical"
								loading={loading_contacts}
								grid={{
									column: 1,
									gutter: 16,
								}}
								dataSource={contacts}
								renderItem={renderItemList}
							/>
						}
					</div>
				</Modal>
			</Layout>
		);
	}
}
export default Afiliados;
