import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import "./login.css";
import { LoginLayout } from "../../layouts";
import LoginForm from "../../components/login/LoginForm";
import { authentication, getUserInfo } from "../../services";
import { authenticate } from "../../redux/actions";

class Login extends Component {
	handleSubmit = (err, data) => {
		let { history, actions } = this.props;
		if (!err) {
			/* return history.push("/home"); */
			authentication(data)
				.then((response) => {
					getUserInfo().then((user) => {
						actions.authenticate({ loggedIn: true, user });
						history.push("/home");
					});
				})
				.catch((err) => message.error(err.message));
		}
	};
	render() {
		return (
			<LoginLayout>
				<div className="login-form">
					<LoginForm onSubmit={this.handleSubmit} />
				</div>
			</LoginLayout>
		);
	}
}

const mapStateToProps = (state) => {
	const { loggedIn } = state;
	return {
		loggedIn,
	};
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators({ authenticate }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
