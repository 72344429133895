import {
	authenticate,
	average_units_sold_year_month,
	buy_camacol_categories,
	companies_contacts,
	company_service,
	contact_deparment_service,
	contact_position_service,
	current,
	getService,
	graph_filters,
	graphics_entity,
	graphis_new_home,
	group_options,
	housing_units_construction_phase,
	locations_cities,
	Logout,
	new_housing_releases,
	projects_join_coorde_urba,
	reAuthenticate,
	sale_housing_by_price_range,
	segmentation_options,
	top_10_projects_higher_sales,
	type_affiliates,
	user_roles,
	tramitesConstruccionService,
	tramitesConstruccionEmailService
} from "./services";

export const authentication = ({ strategy = "local", email, password }) => {
	return authenticate({
		strategy,
		email,
		password,
	});
};
export const reautentication = reAuthenticate;
export const companiescontacts = (company_id) =>
	companies_contacts.find({
		query: {
			company_id,
		},
	});
export const logout = Logout;
export const groupsOption = () => group_options.find({});
export const typeAffiliates = () => type_affiliates.find({});
export const segmentationOptions = () => segmentation_options.find({});
export const getUserInfo = () => current.create({});
export const companyService = (params) =>
	company_service.patch(params.id, params);
export const getCompanyInfo = (id) => company_service.get(id);
export const getContactDepartaments = () =>
	contact_deparment_service.find({
		$limit: 50000,
	});
export const getContactPositions = () =>
	contact_position_service.find({
		$limit: 50000,
	});
export const createContact = (params) => companies_contacts.create(params);
export const editContact = (id, params) => companies_contacts.patch(id, params);
export const removeContact = (id) => companies_contacts.remove(id);
export const getUserRoles = () => user_roles.find({});
export const getCities = () => locations_cities.find({});
export const getBuyCategories = () => buy_camacol_categories.find({});
export const searchFilter = (filters = {}) => {
	return graph_filters.find({
		query: filters,
	});
};

export const getConstructionProcedures = () => {
	return tramitesConstruccionService.find({
		query: {
			status: 'active',
			// $sort: { position: 1 } 
		}
	})
}
export const getSingleConstructionProcedures = (sendId) => {
	return tramitesConstruccionService.find({
		query: {
			id: `${sendId}`
		}
	});
}

export const getCallSendEmailConstructionProcedures = (data) => {
	return tramitesConstruccionEmailService.create({ ...data, type: 'email', typeNotification: "tramites-camacol-contact" })
}

export const getNewHomes = (filters = {}) => {
	return graphis_new_home.find({
		query: filters,
	});
};
export const getTop10ProjectsHigherSale = (filters = {}) => {
	return top_10_projects_higher_sales.find({
		query: filters,
	});
};
export const getAverageUnitsSold = (filters = {}) => {
	return average_units_sold_year_month.find({
		query: filters,
	});
};
export const getEntities = (filters = {}) => {
	return graphics_entity.find({
		query: filters,
	});
};
export const getSaleHousingByPriceRange = (filters = {}) => {
	return sale_housing_by_price_range.find({
		query: filters,
	});
};
export const getProjectsJoinCoordeUrban = (filters = {}) => {
	return projects_join_coorde_urba.find({
		query: filters,
	});
};
export const getUnitiesPhase = (filters = {}) => {
	return housing_units_construction_phase.find({
		query: filters,
	});
};
export const getNewHousingReleases = (filters = {}) => {
	return new_housing_releases.find({
		query: filters,
	});
};


export { getService, company_service };
