import React, { Component } from "react";
import Form from "../../components/com/form/AdvancedForm";
import { Icon, Radio } from "antd";
import "./formsearch.css";
import { SearchBox, Select } from "../algolia";

const { Button } = Radio;
class CompanyForm extends Component {
	render() {
		return (
			<div className="form-search-container">
				<Form autoSubmit={false}>
					<>
						<div className="form-search-title" style={{ width: "100%" }}>
							<h2>
								<Icon type="search" /> BUSCA AQUÍ
							</h2>
						</div>

						<SearchBox
							label="Nombre de empresa"
							style={{ width: "100%" }}
							placeholder="Nombre de empresa"
							name="filter"
						/>

						<Select
							name="group.name"
							attribute={"group.name"}
							label="Grupo"
							sort={"ASC"}
							style={{ width: "100%" }}
							placeholder="Seleccione Grupo"
						/>
						<Select
							name="segmentations"
							attribute={"sementations.segmentation.name"}
							sort={"ASC"}
							label="Segmentaciones"
							style={{ width: "100%" }}
							placeholder="Segmentaciones"
						/>
						<Select
							type="hidden"
							attribute={"affiliate_status_id"}
							defaultValue={1}
						/>
					</>
				</Form>
			</div>
		);
	}
}
export default CompanyForm;
