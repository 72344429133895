import React from "react";
import {
	CartesianGrid,
	Line,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
	ResponsiveContainer,
} from "recharts";

const data = [
	{
		name: "enero 2019",
		uv: 4000,
		pv: 2400,
		amt: 2400,
	},
	{
		name: "marzo 2019",
		uv: 3000,
		pv: 1398,
		amt: 2210,
	},
	{
		name: "mayo 2019",
		uv: 2000,
		pv: 9800,
		amt: 2290,
	},
	{
		name: "jul 2019",
		uv: 2780,
		pv: 3908,
		amt: 2000,
	},
	{
		name: "sep 2019",
		uv: 1890,
		pv: 4800,
		amt: 2181,
	},
];
const colors = ["#9B3C84", "#1B2362"];

const CustomLineChart = ({ size = 780, ...props }) => {
	return (
		<>

		<LineChart
			width={size}
			height={400}
			data={props.data || []}
			margin={{
				top: 20,
				right: 0,
				left: -20,
				bottom: 20,
			}}
		>
			<CartesianGrid /* strokeDasharray="3 3" */ />
			<XAxis dataKey={props.xaxis || "name"} />
			<YAxis />
			<Tooltip />
			{/*  <Legend /> */}
			{props.lines.map((line, index) => {
				return (
					<Line
						type="monotone"
						dataKey={line.key}
						stroke={colors[index]}
						/* strokeDasharray="5 5" */
					/>
				);
			})}
			{/* <Line
          type="monotone"
          dataKey="pv"
          stroke="#9B3C84"
        />
        <Line
          type="monotone"
          dataKey="uv"
          stroke="#1B2362"
           strokeDasharray="3 4 5 2"
        /> */}
		</LineChart>
		</>
	);
};

export default CustomLineChart;
