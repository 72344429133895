import React, { Component } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import "./form.css";

const footerStyle = {
	marginTop: 20,
};
class AdvancedForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expand: false,
			formLayout: "vertical",
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (typeof this.props.onSubmit !== "undefined") {
				this.props.onSubmit(err, values);
				//if (!err) this.props.form.resetFields();
			}
		});
	};

	handleReset = () => {
		this.props.form.resetFields();
	};
	toggle = () => {
		const { expand } = this.state;
		this.setState({ expand: !expand });
	};
	handleChange = (key, value) => {
		this.props.form.setFieldsValue({
			[key]: value,
		});
	};
	checkdate = (rule, value, callback) => {
		if (value) {
			callback();
			return;
		}
		callback("Date is required!");
	};
	// To generate mock Form.Item
	getFields() {
		const me = this;
		const { getFieldDecorator } = this.props.form;
		const { children } = this.props;
		let childrens = [];

		let { formLayout } = this.state;
		formLayout = this.props.formLayout || formLayout;

		const formItemLayout =
			formLayout === "vertical"
				? {
					span: 12,
				}
				: null;

		if (typeof children !== "undefined") {
			try {
				let { props } = children;
				let childNodes = props.children || [];
				childrens = React.Children.map(childNodes, (child, index) => {
					if (!child) return child;
					let {
						name,
						label,
						required,
						message,
						validations,
						style,
						initial,
						initialValue,
						onChange,
						type,
						xtype,
						flex,
						mode,
					} = child.props;

					name = typeof name === "undefined" ? `field_${index}` : name;

					style = style || {};

					/* InitialValue Map */
					let { latName = "lat", lngName = "lng" } = child.props;
					let { form } = me.props;
					if (xtype === "map") {
						let values = form.getFieldsValue([latName, lngName]);
						initialValue = Object.values(values);
					}

					validations = validations || [
						{
							required: required || this.props.allRequired || false,
							message: message || `${label} es requerido`,
						},
					];
					if (type === "email") {
						validations.push({
							type: "regexp",
							pattern: new RegExp(
								"^([a-zd.-]+)@([a-zd-]+).([a-z]{2,8})(.[a-z]{2,8})?$"
							),
							message: "Wrong format!",
						});
					}
					delete child.value;
					if (type === "hidden") {
						style = {
							width: "100%",
							height: 0,
							margin: 0,
							padding: 0,
						};
					}

					if (flex <= 1) style["width"] = `${flex * 100}%`;
					return (
						<Col
							{...formItemLayout}
							key={index}
							style={{ ...style, padding: 5, display: "inline-block" }}
							className={
								typeof child.className != "undefined"
									? "item-form " + child.className
									: "item-form"
							}
						>
							<Form.Item className={`field ${type || ""}`} label={label}>
								{getFieldDecorator(name || `field-${index}`, {
									initialValue: initialValue || initial,
									/*  trigger: "focus", */
									validateTrigger: "onChange",
									/* trigger: "dateChange", */
									valuePropName: "value",

									rules: validations,

									onChange: function (key, value) {
										if (xtype === "map") {
											let { form } = me.props;
											/*  let { latName = "lat", lngName = "lng" } = child.props; */
											value = key;
											let { lng, lat } = value;
											form.setFieldsValue({ [lngName]: lng });
											form.setFieldsValue({ [latName]: lat });
										}
										if (value)
											if (typeof value === "object") {
												if (Array.isArray(value)) {
													if (mode === "multiple") {
														/* SelectField */
														value = value.map((item) => {
															let { props } = item;
															return props.value;
														});
													}
													value = value.join(",");
												} else {
													value = key;
												}
											}

										me.handleChange(name, key);

										if (typeof key === "object")
											if ("target" in key) value = key.target.value;
										if (typeof onChange !== "undefined") onChange(name, value);
									},
								})(
									React.cloneElement(child, {
										style: { width: "100%" },
										trigger: "focus",
										//form
									})
								)}
							</Form.Item>
						</Col>
					);
				});
			} catch (err) { }
		}

		return childrens;
	}

	componentDidMount() {
		let { formLayout } = this.props;
		if (typeof formLayout !== "undefined")
			this.setState({
				formLayout,
			});
	}
	componentWillUnmount() {
		this.props.form.resetFields();
	}
	render() {
		const { formLayout } = this.state;
		let {
			footer,
			className,
			style,
			autoSubmit = true,
			textAcceptButton = "GUARDAR",
			submitting = false,
		} = this.props;
		return (
			<Form
				ref={this.props.ref}
				className={className || "advanced-form"}
				layout={formLayout}
				onSubmit={this.handleSubmit}
				style={style || { margin: 20 }}
			>
				<Row gutter={24}>{this.getFields()}</Row>
				<Row
					className={
						typeof footer !== "undefined" ? "footer-advanced-form" : null
					}
				>
					{autoSubmit ? (
						<>
							<Row type="flex" justify="center">
								<Col span={24}>
									<Divider
										style={{
											width: "100%",
											margin: "20px 0px 20px 0px",
										}}
									/>
								</Col>
								<Col span={24} align="center">
									<Button
										type="primary"
										loading={submitting}
										htmlType="submit"
										className="login-form-button btn-green"
										style={
											this.props.buttonSubmitStyle || {
												width: "32%",
												height: 40,
												padding: 5,
												margin: 10,
												textAlign: "center",
											}
										}
										size="large"
										block
									>
										{textAcceptButton}
									</Button>
								</Col>
							</Row>
							<Row
								style={footerStyle}
								type="flex"
								justify="center"
								align="middle"
							>
								<Col span={24}>{footer}</Col>
								<Col span={24}>
									{!autoSubmit && (
										<Divider
											style={{
												width: "100%",
												margin: "20px 0px 20px 0px",
											}}
										/>
									)}
								</Col>
							</Row>
						</>
					) : (
						<Row
							style={footerStyle}
							type="flex"
							justify="center"
							align="middle"
						>
							<Col span={24}>{footer}</Col>
						</Row>
					)}
				</Row>
			</Form>
		);
	}
}
const WrappedAdvancedForm = Form.create({ name: "search_form" })(AdvancedForm);

export default WrappedAdvancedForm;
