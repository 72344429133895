import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import AdvancedForm from "../com/form/AdvancedForm";
import { Button, Icon, Input, Row } from "antd";
import ReactGA from 'react-ga4';
import { getUserInfo } from "../../services"; 

import "./loginform.css";

const LoginForm = (props) => { 

	useEffect(() => {
		// No hacemos nada aquí durante el montaje
		// Cleanup: se ejecuta solo cuando el componente se desmonte
		return () => {
		//   console.log('Componente desmontado');

		  getUserInfo().then(user => {
			ReactGA.event({
				category: 'User',
				action: 'Login',
				label: 'Inicio de sesión exitoso',
				name: `${user.first_name} ${user.last_name}`,
				company: user.company.name,
				companyId:user.company.id
			  });

			  console.log('Usuario inició sesión');
			  console.log(user)
		})
		  // Aquí pones la lógica que quieres que se ejecute solo al desmontar
		};
	  }, []);


	return (
	<Row
		className="login-form-container"
		type="flex"
		justify="center"
		align="middle"
	>
		<AdvancedForm
			onSubmit={(err, data) => props.onSubmit(err, data)}
			layout={"vertical"}
			autoSubmit={false}
			textAcceptButton="Iniciar Sesión"
			footer={
				<Row type="flex" justify="start" align="middle">
					<Button
						block
						size="large"
						style={{
							width: "50%",
							height: 40,
							padding: 5,
							margin: "0px auto",
							textAlign: "center",
						}}
						type="primary"
						htmlType="submit"
						className="login-form-button"
						
					>
						Iniciar Sesión
					</Button>
				</Row>
			}
		>
			<>
				<div
					style={{
						width: "100%",
					}}
				>
					<h2 className="login-title">
						<span>Bienvenido a </span>
						<span className="text-primary">Camacol</span>
					</h2>
				</div>
				<Input
					size="large"
					style={{ width: "100%" }}
					label="CORREO ELECTRÓNICO"
					placeholder="Correo"
					name="email"
					validator={[{ required: true, message: "Debe ingresar un correo" }]}
					prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
				/>
				<Input
					size="large"
					style={{ width: "100%" }}
					label="CONTRASEÑA"
					type="password"
					prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
					placeholder="Contraseña"
					name="password"
					validator={[
						{ required: true, message: "Debe ingresar su contraseña" },
					]}
				/>
			</>
		</AdvancedForm>
	</Row>
	);
}
	
export default withRouter(LoginForm);
