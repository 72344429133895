import React, { useEffect, useState } from "react";
import Layout from "../../layouts/home_layout/layout";
import { Affix, Avatar, Col, message, Row } from "antd";
import algoliasearch from "algoliasearch";
import {
	API_KEY,
	APPLICATION_ID,
	S3_INSTANCE_CAMACOL,
} from "../../utils/Constants";
import MapBox from "../../components/com/map/MapBox";
import { Modal } from "../../components/com";
import { decimal, money } from "../../utils/Helper";
import LandForm from "./LandForm";
import LandDetail from "./LandDetail";
import ProjectDetail from "./ProjectDetail";
import { lands } from "../../services/services";
import { getUserInfo } from "../../services";

const projectMarker = require("../../resources/images/project_marker.png");
const landMarker = require("../../resources/images/land_marker.png");

const client = algoliasearch(APPLICATION_ID, API_KEY);

const facets = ["real_estate_registration", "city.name"];
const search = (query, params) => {
	const queries = [
		{
			indexName: "lands",
			searchClient: client,
			query,
			params: {
				facets,
				/* hitsPerPage: 54000 */
			},
		},
		{
			indexName: "projects",
			params: {
				facets,
				...params,
			},
		},
	];

	return new Promise((resolve, reject) => {
		client.search(queries, (err, { results } = {}) => {
			if (err) return reject(err);
			resolve(results);
		});
	});
};

const Lands = () => {


	const [data, setData] = useState([]);
	let [cities, setCiudades] = useState([]);
	let [ids, setIds] = useState([]);
	let [show_modal, showModal] = useState(false);
	const [record, setRecord] = useState({});
	let [neighborhoods, setNeighborhoods] = useState([]);
	let [neighborhoods_names, setNeighborhoodsNames] = useState([]);


	const fetchProjects = async (name, query) => {
		await search(name, query)
			.then((results) => {
				let data = [];
				if (results.length > 0) {
					let lands = results[0];
					lands.hits.reduce((prev, current) => {
						let { city, neighborhood } = current;
						if (ids.indexOf(city.id) === -1) {
							ids.push(city.id);
							cities.push(city);
						}
						if (neighborhoods_names.indexOf(neighborhood.name)) {
							neighborhoods_names.push(neighborhood.name);
							neighborhoods.push(neighborhood);
						}

						return current;
					}, ids);
				}
				results.reduce((prev, current) => {
					let { hits } = current;
					data = [...data, ...hits];
					return current;
				}, data);
				setData(data);
			})
			.catch((err) => message.error(err.message));
	};


	// const pushUserDataLayer = () => {
	// 	//-----------------------------------------------
	// 	window.dataLayer = window.dataLayer || [];
	// 	getUserInfo().then((user) => {
	// 		const {company: { nit, name }, first_name } = user; 

	// 		const nuevoEvento = {
	// 			constructora: "Marval_Test", 
	// 			nombre_afiliado: "name",
	// 			nit_company: "0",
	// 			event: "file_download",
	// 		};

	// 		nuevoEvento.constructora = name;
	// 		nuevoEvento.nombre_afiliado = first_name;
	// 		nuevoEvento.nit_company = nit; 

	// 		console.log({user}); 

	// 		window.dataLayer[0] = nuevoEvento; 
	// 	});
	// 	//----------------------------------------------------------
	// }

	const renderMarker = (record, index) => {
		let { type } = record;
		return (
			<img
				src={type === "lands" ? landMarker : projectMarker}
				width={40}
				height={50}
			/>
		);
	};

	const handleChange = (name, value) => {
		switch (name) {
			case "city.name":
				cities = [];
				break;

			default:
				break;
		}
		fetchProjects(value);
	};

	const handleClick = async (item) => {
		console.log({ item },);

		const landRes = await lands
			.get(item.id)
			.then((res) => {
				console.log({ res });
				return res
			})
			.catch((err) => console.log(err));
		setRecord({ ...landRes, ...item });
		showModal(true);
	};

	const searchGeoRadius = (
		query,
		point,
		aroundRadius = 10000 /* 1000m = 1km*/
	) => {
		let { lngLat } = point;
		if (lngLat) {
			let { lng, lat } = lngLat;
			let projectIndex = client.initIndex("projects");
			let landIndex = client.initIndex("lands");

			fetchProjects("", {
				aroundLatLng: `${lat}, ${lng}`,
				aroundRadius,
				hitsPerPage: 20,
			});
		}
	};
	const handleDblClick = (e, point) => {
		searchGeoRadius("", point);
	};

	useEffect(() => {
		fetchProjects();
		// pushUserDataLayer(); 
		return () => { };
	}, []);

	let { affiliate = {} } = record;
	return (
		<Layout>
			{
				<Affix
					offsetTop={120}
					style={{
						position: "absolute",
						left: "100px",
						top: "10%",
						width: 300,
						height: "auto",
					}}
				>
					<Row
						style={{
							width: "100%",
							height: "100%",
							borderRadius: 8,
							background: "#fff",
							zIndex: 8,
							padding: 8,
						}}
					>
						<Col>
							<LandForm
								onChange={handleChange}
								cities={cities}
								neighborhoods={neighborhoods}
							/>
						</Col>
					</Row>
				</Affix>
			}
			<MapBox
				data={data}
				renderMarker={renderMarker}
				onDblClick={handleDblClick}
				onDragEnd={(center) => {
					searchGeoRadius("", { lngLat: center });
				}}
				onMarkClick={handleClick}
			/>
			<Modal
				width={record.type === "lands" ? 900 : 600}
				className="modal-detail"
				footer={null}
				onCancel={() => {
					showModal(false);
					setRecord({});
				}}
				title={
					record.type === "lands" && (
						<div>
							<Row type="flex" justify="start" align="top" gutter={16}>
								<Col span={12}>
									<Row type="flex" justify="start" align="top" gutter={16}>
										<Col span={6} align="center">
											<Avatar
												shape="circle"
												size={80}
												style={{
													margin: "20px 8px",
												}}
												src={`${S3_INSTANCE_CAMACOL}${affiliate.logo}`}
											/>
										</Col>
										<Col span={18}>
											<div className="detail-info">
												<div>
													<h2>DATOS DE CONTACTO</h2>
												</div>
												<div>
													<span>Empresa:</span>
													<span className="value-field"> {affiliate.name}</span>
												</div>
												<div>
													<span>Dirección:</span>
													<span className="value-field">
														{" "}
														{record.name}
													</span>
												</div>
												{affiliate.mobile_phone_number ?
													<div>
														<span>Celular: </span>
														<span className="value-field">
															{affiliate.mobile_phone_number}
														</span>
													</div>
													: ""}

												{affiliate.fix_phone_number ?
													<div>
														<span>Teléfono: </span>
														<span className="value-field">
															{affiliate.fix_phone_number}
														</span>
													</div>
													: 'b'}


											</div>
										</Col>
									</Row>
								</Col>
								<Col span={12}>
									<div className="detail-info">
										<div>
											<h2>
												{" "}
												<Avatar
													style={{ backgroundColor: "#002259" }}
													icon="environment"
												/>{" "}
												INFORMACIÓN DEL LOTE
											</h2>
										</div>
										<div>
											<span>Referencia Catastral: </span>
											<span className="value-field">
												{record.real_estate_registration}
											</span>
										</div>
										<div>
											<span>Precio:</span>
											<span className="value-field">
												{" "}
												{money(record.price, {
													presicion: 0,
												})}
											</span>
										</div>
										<div>
											<span>Área: </span>
											<span className="value-field">
												{decimal(record.area_m2, {
													symbol: "m2",
													presicion: 0,
												})}
											</span>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					)
				}
				visible={show_modal}
			>
				<div>
					{record.type === "lands" ? (
						<LandDetail {...record} />
					) : (
						<ProjectDetail {...record} />
					)}
				</div>
			</Modal>
		</Layout>
	);
};
export default Lands;
