import React from "react";
import { Modal } from "antd";
import "./modal.css";

const MyModal = (props) => (
	<Modal
		width={props.width || 520}
		className={`my-modal ${props.className}` || "my-modal"}
		closable={props.closable || true}
		title={props.title}
		maskClosable={props.maskClosable || true}
		footer={props.footer}
		centered
		cancelButtonProps={{ disabled: props.cancelButtonDisabled || false }}
		cancelText={props.cancelText}
		okText={props.okText}
		okButtonProps={{
			type: "primary",
			disabled: props.okButtonDisabled || false,
		}}
		visible={props.show || props.visible}
		onOk={props.onOk}
		onCancel={props.onCancel}
	>
		{props.children}
	</Modal>
);

export default MyModal;
