/* Custom Actions */
const authenticate = (user) => {
	return {
		type: "AUTHENTICATE",
		user,
	};
};
const isLoggedIn = (loggedIn) => {
	return {
		type: "ISLOGGEDIN",
		loggedIn,
	};
};
export { authenticate, isLoggedIn };
