import React from "react";
import { Card, Icon } from "antd";
import { Link } from "react-router-dom";
import { S3_INSTANCE_CAMACOL } from "../../utils/Constants"

const { Meta } = Card;

// const Test = styled(div)`

// `

const CardItem = (props) => (
    <div>
        <Card
            className="card-item"
            hoverable
            style={{ width: 240 }}
            cover={
                <div className="card-cover-container">
                    <Link to={`/tramites_construccion/${props.id}` || ""}>
                        <div
                            className="card-cover"
                            alt="example"
                            style={{
                                backgroundImage: `url(${S3_INSTANCE_CAMACOL}${props.icon_path ||
                                    "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                                    })`,
                            }}
                        ></div>
                    </Link>
                </div>
            }
        >
        </Card>
        <Meta
            title={
                <div className="card-title" style={{ display: 'flex', justifyContent: 'center', alignItem: 'center', marginTop: '10px', width: 240 }}>
                    {props.icon_path && <Icon type={props.icon_path} />}
                    <Link to={`/tramites_construccion/${props.id}` || ""}>
                        <h2>{props.city_name}</h2>
                    </Link>
                </div>
            }
            description={props.description}
        />

    </div>
);
export default CardItem;
