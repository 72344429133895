import React, { useEffect, useState } from "react";
import Layout from "../../layouts/main_layout/mainLayout";
import { CRUD } from "../../components/com";
import { Col, Row } from "antd";
import { lands_service } from "../../services/services";
import { getCities } from "../../services";
import "./crud_land.css";

const Land = (props) => {
	const [user, setUser] = useState({});
	useEffect(() => {
		let { history } = props;
		if (localStorage.getItem("user")) {
			let user = JSON.parse(localStorage.getItem("user"));
			setUser(user);
		}
		// if (!allowAccess()) return history.replace("/");
		return () => {};
	}, []);
	return (
		<Layout /* className="layout-crud-land" */>
			<Row type="flex" justify="center" align="top">
				<Col span={18}>
					{user.company_id && (
						<CRUD
							allowCreate={true}
							params={{
								query: {
									affiliate_id: user.company_id,
								},
							}}
							title="Administrar Lotes"
							service={lands_service}
							fields={[
								{
									name: "id",
									type: "hidden",
								},
								{
									name: "real_estate_registration",
									label: "Matrícula inmobiliaría",
									placeholder: "Matrícula inmobiliaría",
									validations: [
										{
											required: true,
											message: "Debe ingresar Matrícula inmobiliaría",
										},
									],
								},
								{
									name: "area_m2",
									label: "Área",
									placeholder: "Área",
									validations: [
										{
											required: true,
											message: "Debe ingresar Área",
										},
									],
								},
								{
									xtype: "selectfield",
									name: "city_id",
									label: "Ciudad",
									model: {
										service: getCities,
									},
									placeholder: "Ciudad",
									validations: [
										{
											required: true,
											message: "Debe ingresar Ciudad",
										},
									],
								},
								{
									name: "neighborhood",
									label: "Barrio",
									placeholder: "Barrio",
									validations: [
										{
											required: true,
											message: "Debe ingresar Barrio",
										},
									],
								},
								{
									name: "price",
									label: "Precio",
									placeholder: "Precio",
									validations: [
										{
											required: true,
											message: "Debe ingresar Precio",
										},
									],
								},
								{
									name: "name",
									label: "Dirección",
									placeholder: "Dirección",
									flex: 1,
									validations: [
										{
											required: true,
											message: "Debe ingresar Dirección",
										},
									],
								},
								{
									name: "_geoloc",
									title:
										"Haz click en la ubicación de tu lote para rellenar los campos de latitud y longitud",
									xtype: "map",
									lngName: "long",
									flex: 1,
								},
								{
									name: "lat",
									label: "Latitud",
									placeholder: "Latitud",
									validations: [
										{
											required: true,
											message: "Debe ingresar Latitud",
										},
									],
								},
								{
									name: "long",
									label: "Longitud",
									placeholder: "Longitud",
									validations: [
										{
											required: true,
											message: "Debe ingresar Longitud",
										},
									],
								},
								{
									xtype: "textarea",
									name: "description",
									label: "Descripción",
									style: { width: "100%" },
									placeholder: "Descripción",
									validations: [
										{
											required: true,
											message: "Debe ingresar Descripción",
										},
									],
								},
							]}
							columns={[
								{
									dataIndex: "real_estate_registration",
									text: "Referecia Catastral",
								},
								{
									dataIndex: "name",
									text: "Nombre",
								},
								/* {
                dataIndex: "description",
                text: "Descripción"
              }, */
								{
									dataIndex: "area_m2",
									text: "Área",
								},
								{
									dataIndex: "price",
									text: "Precio",
								},
							]}
						/>
					)}
				</Col>
			</Row>
		</Layout>
	);
};

export default Land;
