import React from "react";
import Form from "../../components/com/form/AdvancedForm";
import { Col, Radio, Row } from "antd";
import "./formsearch.css";
import { Group, SearchBox, Select } from "../algolia";

const { Button } = Radio;
const CompraForm = (props) => {
	return (
		<div className="form-search-container">
			<Form autoSubmit={false}>
				<>
					<SearchBox
						size="large"
						className="search-box"
						style={{ width: "100%" }}
						placeholder="Escribe aquí palabra clave..."
						name="filter"
					/>
					<Row type="flex" align="middle" justify="start" gutter={4}>
						{
							<Col>
								
								<div className="field-wrapper" style={{ width: "250px" }}>
								<label>Insumos/Servicios</label>
								<Select
									name="Servicios"
									style={{ width: "250px" }}
									attribute={"type"}
									label="Insumo o Servicio"
									placeholder="¿Insumo o Servicio?"
								/>
								
								</div>
							</Col>
						}
					</Row>
					<Row type="flex" align="middle" justify="start" gutter={4}>
						<Col>
							<div className="field-wrapper">
								<label>Categoría</label>
								<Select
									name="category"
									style={{ width: "250px" }}
									attribute={"buy_camacol_category.name"}
									label="Categoría"
									placeholder="Categoría"
								/>
							</div>
						</Col>
						{
							<Col>
								<div className="field-wrapper">
									<label>Ciudad </label>
									<Select
										name="city"
										style={{ width: "250px" }}
										attribute={"locations_cities.location_city.name"}
										label="Ciudad"
										placeholder="Ciudad"
									/>
								</div>
							</Col>
						}
					</Row>
				</>
			</Form>
		</div>
	);
};
export default CompraForm;
