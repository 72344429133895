import React, { useEffect } from "react";
import { Layout } from "antd";
import "./homelayout.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { menuService } from "../../services/services";
import { setMenuHeader } from "../../redux/actions";

const { Content } = Layout;

const CustomLayout = ({ className, children, align }) => {
	const menus = useSelector(state => state.ui.menus) || []
	const dispatch = useDispatch()

	const getMenus = async () => {
		await menuService.find({
			query: { status: 'active', $sort: { position: 1 } }
		}).then(({data}) => {
			dispatch(setMenuHeader(data))
		})
	}

	useEffect(() => {
		if (!menus.length) getMenus()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menus])
	
	return (
		<Layout
			style={{ minHeight: "100vh" }}
			className={className ? className : "home-layout"}
		>
			<Layout className="layout-container">
				<Header />
				<Content style={{ margin: "0px" }}>
					<div>{children}</div>
				</Content>
				<Footer />
			</Layout>
		</Layout>
	)
};
export default CustomLayout;
