const Authentication = (state = {}, action = {}) => {
	switch (action.type) {
		case "AUTHENTICATE":
			return {
				...state,
				...action.user,
			};
			break;
		case "ISLOGGEDIN":
			return {
				...state,
				loggedIn: action.loggedIn,
			};
			break;
		default:
			return state;
			break;
	}
};
export default Authentication;
