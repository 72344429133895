import Api from "./../utils/Api";
import Api2 from "./../utils/Api2";
export const api = Api;
export const current = api.service("current-user");
export const companies_contacts = api.service("companies-contacts");
export const buy_camacol_categories = api.service("buy-camacol-categories");
export const buy_camacol = api.service("buy-camacol");
export const buy_camacol_locations_cities = api.service(
	"buy-camacol-locations-cities"
);
export const group_options = api.service("group-options");
export const type_affiliates = api.service("type-affiliates");
export const segmentation_options = api.service("segmentation-options");
export const contact_deparment_service = api.service(
	"contact-deparment-options"
);
export const contact_position_service = api.service("contact-position-options");
export const company_service = api.service("companies");
export const reAuthenticate = api.reAuthenticate;
export const Logout = api.logout;
export const user_service = api.service("users");
export const lands_service = api.service("lands");
export const locations_cities = api.service("locations-cities");
export const user_roles = api.service("company-role-options");
export const graphis_new_home = api.service("graphics-new-houses");
export const new_housing_releases = api.service(
	"graphics-new-housing-releases"
);
export const top_10_projects_higher_sales = api.service(
	"graphics-top-10-projects-higher-sales"
);
export const average_units_sold_year_month = api.service(
	"graphics-average-units-sold-year-month"
);
export const graphics_entity = api.service("graphics-entity");
export const sale_housing_by_price_range = api.service(
	"graphics-sale-housing-by-price-range"
);
export const projects_join_coorde_urba = api.service(
	"graphics-join-coorde-urba"
);
export const housing_units_construction_phase = api.service(
	"graphis-housing-units-construction-phase"
);
export const graph_filters = api.service("graph-filters");
export const getService = (service) => api.service(service);
export const authenticate = ({ strategy, email, password }) => {
	return api.authenticate({
		strategy,
		email,
		password,
	});
};

export const configurationsService = api.service("configurations");

export const upliftingActivityResources = api.service('uplifting-activity-resources')

export const menuService = api.service('menu')

export const tramitesConstruccionService = api.service('construction-procedures')
export const tramitesConstruccionEmailService = api.service('notifications')

/* Api 2 */
export const alineamientoService = (id) =>
	Api2.get("WebServerAlineamiento/webresources/alineamiento/" + id);
export const catastralService = (params) =>
	Api2.get("WebServiceUsoSuelo/webresources/suelos", {
		params
	});


export const lands = api.service("lands");