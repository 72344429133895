import React, { useState, useEffect } from "react";
import { Button, Col, Divider, Row, Tabs, message } from "antd";
import "./lands.css";
import { alineamientoService, catastralService } from "../../services/services"
import axios from "axios";
import { S3_INSTANCE_CAMACOL } from "../../utils/Constants";
const ImageLogo = require("../../resources/images/bg-alineamiento.png");
const { TabPane } = Tabs;

const default_values = [
	{
		"NOM_PERFIL": "No Disponible",
		"NOM_VIA": "No Disponible",
		"JERARQUIA": "No Disponible",
		"LB_LC": "No Disponible"
	}
]
//S3_INSTANCE_CAMACOL
const LandDetail = ({ real_estate_registration, ...props }) => {
	console.log("arr===>", props)
	let { affiliate } = props;
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const getData = () => {
		setLoading(true);
		alineamientoService(real_estate_registration)
			.then(({ data }) => {
				setData(data);
				setLoading(false);
			})
			.catch(err => {
				setData(default_values);
				setLoading(false);
				message.error(err.message);
			});
	}

	// useEffect(() => {
	// 	getData();
	// 	return () => {
	// 	}
	// }, []);
	return (
		<div>
			<Tabs className="tab-detail-land" defaultActiveKey="1" size="small">
				<TabPane
					tab={
						<h2
							style={{
								fontWeight: "500",
							}}
						>
							ALINEAMIENTO
						</h2>
					}
					key="1"
				>
					<div className="tab-container">
						<Row type="flex" justify="start" align="top" gutter={18}>
							<Col span={8}>
								<div>
									<div>
										<div
											className="avatar-detail"
											style={{
												backgroundImage: `url(${ImageLogo})`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "100% 100%",
											}}
										/>
									</div>
								</div>
							</Col>
							<Col span={16}>
								<Row align="top">
									<Col>
										<h2>ALINEAMIENTO</h2>
									</Col>
									<Col>
										<div className="detail-info">
											<div>
												<span>Barrio: {props.alignment_neighborhood}</span>
											</div>
											<div>
												<span>Pieza: {props.alignment_piece}</span>
											</div>
										</div>
									</Col>
								</Row>
								<Row
									style={{
										width: "100%",
										margin: "40px 10px",
									}}
									type="flex"
									justify="space-between"
									align="middle"
								>
									<Col span={16}>
										<Row
											style={{
												padding: 4,
												marginBottom: 4,
											}}
											type="flex"
											justify="space-between"
											align="middle"
										>
											<Col span={5}>
												<strong>Nom. vía</strong>
											</Col>
											<Col span={5}>
												<strong>Nom. perfil</strong>
											</Col>
											<Col span={5}>
												<strong>Jerarquía</strong>
											</Col>
											<Col span={5}>
												<strong>LBLC</strong>
											</Col>
										</Row>
										{props.land_alignments.map((item) => (
											<Row
												style={{
													padding: 4,
													borderBottom: "1px solid #cccccc6b",
												}}
												type="flex"
												justify="space-between"
												align="middle"
											>
												<Col span={5}>{item.street_name || ""}</Col>
												<Col span={5}>{item.profile_name || ""}</Col>
												<Col span={5}>{item.hierarchy || ""}</Col>
												<Col span={5}>{item.lb_lc || ""}</Col>
											</Row>
										))}
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</TabPane>
				<TabPane
					tab={
						<h2
							style={{
								fontWeight: "500",
							}}
						>
							EDIFICABILIDAD
						</h2>
					}
					key="2"
				>
					<div className="tab-container">
						<Row type="flex" justify="start" align="top" gutter={18}>
							<Col span={8}>
								<h2>EDIFICABILIDAD</h2>
							</Col>
							<Col span={16}>
								<Row type="flex" justify="start" align="top" gutter={18}>
									<Col span={7}>
										<div>
											<span>Dirección: {props.buildability_neighborhood}</span>
										</div>
										<div>
											<span>Pieza: {props.buildability_piece}</span>
										</div>
									</Col>
									<Col span={7}>
										<div className="detail-info">
											<div>
												<span>Polígono: {props.buildability_poligon}</span>
											</div>
											<div>
												<span>Tratamiento: {props.buildability_treatment}</span>
											</div>
										</div>
									</Col>
									<Col span={7}>
										<div>
											<span>Modalidad: {props.buildability_modality}</span>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Divider />
						<Row type="flex" justify="start" align="top" gutter={18}>
							<Col span={8}>
								<h2>EDIFICABILIDAD BÁSICA</h2>
							</Col>
							<Col span={16}>
								<Row type="flex" justify="start" align="top" gutter={18}>
									<Col span={24}>
										<Row
											style={{
												padding: 4,
												marginBottom: 4,
												justifyContent: "center",
												alignItems: "center",
											}}
											type="flex"
											justify="space-between"
											align="middle"
										>
											<Col span={7} style={{
												justifyContent: "center",
												alignItems: "center", display: "flex"
											}}>
												<strong>Rango</strong>
											</Col>
											<Col span={7} style={{
												justifyContent: "center",
												alignItems: "center", display: "flex"
											}}>
												<strong>Densidad Max</strong>
											</Col>
											<Col span={7} style={{
												justifyContent: "center",
												alignItems: "center", display: "flex"
											}}>
												<strong>Altura Max</strong>
											</Col>
										</Row>
										{props.land_buildability.filter(item => item.type === "basic").map((item) => (
											<Row
												style={{
													padding: 4,
													borderBottom: "1px solid #cccccc6b",
													justifyContent: "center",
													alignItems: "center",
												}}
												type="flex"
												justify="space-between"
												align="middle"
											>
												<Col span={7} style={{
													justifyContent: "center",
													alignItems: "center", display: "flex"
												}}>{item.range || ""}</Col>
												<Col span={7} style={{
													justifyContent: "center",
													alignItems: "center", display: "flex"
												}}>{item.max_density || ""}</Col>
												<Col span={7} style={{
													justifyContent: "center",
													alignItems: "center", display: "flex"
												}}>{item.max_height || ""}</Col>
											</Row>
										))}
									</Col>
								</Row>
							</Col>
							<Divider />
						</Row>


						<Row type="flex" justify="start" align="top" gutter={18}>
							<Col span={8}>
								<h2>EDIFICABILIDAD MÁXIMA</h2>
							</Col>
							<Col span={16}>
								<Row type="flex" justify="start" align="top" gutter={18}>
									<Col span={24}>
										<Row
											style={{
												padding: 4,
												marginBottom: 4,
												justifyContent: "center",
												alignItems: "center",
											}}
											type="flex"
											justify="space-between"
											align="middle"
										>
											<Col span={7} style={{
												justifyContent: "center",
												alignItems: "center", display: "flex"
											}}>
												<strong>Rango</strong>
											</Col>
											<Col span={7} style={{
												justifyContent: "center",
												alignItems: "center", display: "flex"
											}}>
												<strong>Densidad Max</strong>
											</Col>
											<Col span={7} style={{
												justifyContent: "center",
												alignItems: "center", display: "flex"
											}}>
												<strong>Altura Max</strong>
											</Col>
										</Row>
										{props.land_buildability.filter(item => item.type === "max").map((item) => (
											<Row
												style={{
													padding: 4,
													borderBottom: "1px solid #cccccc6b",
													justifyContent: "center",
													alignItems: "center",
												}}
												type="flex"
												justify="space-between"
												align="middle"
											>
												<Col span={7} style={{
													justifyContent: "center",
													alignItems: "center", display: "flex"
												}}>{item.range || ""}</Col>
												<Col span={7} style={{
													justifyContent: "center",
													alignItems: "center", display: "flex"
												}}>{item.max_density || ""}</Col>
												<Col span={7} style={{
													justifyContent: "center",
													alignItems: "center", display: "flex"
												}}>{item.max_height || ""}</Col>
											</Row>
										))}
									</Col>
								</Row>
							</Col>
						</Row>
						<Divider />
					</div>
				</TabPane>
				<TabPane
					tab={
						<h2
							style={{
								fontWeight: "500",
							}}
						>
							USO DE SUELO
						</h2>
					}
					key="3"
				>
					<div className="tab-container">
						<Row
							style={{
								height: 400,
							}}
							type="flex"
							justify="center"
							align="middle"
							gutter={18}
						>
							<Col align="center">
								<div>
									<h2>USO DEL SUELO</h2>
								</div>
								{
									props.land_use_path ? (
										<div>
											<Button href={`${S3_INSTANCE_CAMACOL}${props.land_use_path}`} className="btn-green">Descargar</Button>
										</div>
									) : (
										<div>
											<h3>No tiene archivo de descarga.</h3>
										</div>
									)
								}
							</Col>
						</Row>
						<Divider />
					</div>
				</TabPane>
			</Tabs>
		</div>
	);
};

export default LandDetail;
