import React, { useEffect, useState } from "react";
import Layout from "../../layouts/home_layout/layout";
import { Col, Divider, Row, Box } from "antd";
import Lienar from "../../components/graficos/LineChart";
import PayChart from "../../components/graficos/PayChart";
import { DatePicker, Space } from 'antd';
import DataTable from '../../components/graficos/UserDatatable'; 

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    // Función que simula la obtención de datos de una API
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch('https://jsonplaceholder.typicode.com/posts');
    //     const result = await response.json();
    //     setData(result);  // Actualiza el estado con los datos obtenidos
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   } finally {
    //     setLoading(false);  // Cambia el estado de carga una vez que los datos se han obtenido
    //   }
    // };
    // fetchData();

  }, []);

  const dateFormat = 'YYYY/MM/DD';

  return (
    <Layout>
      <h1>Estadisticas</h1>
      {/* <Col>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "white",
            borderRadius: "0.5rem",
            margin: "1rem",
            padding:"1rem"
          }}
        >
          <RangePicker
            defaultValue={[
            //   dayjs("2015/01/01", dateFormat),
            //   dayjs("2015/01/01", dateFormat),
            ]}
            format={dateFormat}
          />
          
        </div>
      </Col>
      <Col style={{ padding: "1rem" }}>
        <Row gutter={15} style={{ display: "flex" }}>
          <Col span={15}>
            <Lienar 
              title={"Total de click por pagina"} 
              serie={[13, 156, 617, 718, 89]}
            />
          </Col>
          <Col span={9} style={{ display: "flex" }}>
            <Row style={{ minWidth: "100%" }}>
              <PayChart
                title={"Inicio de sección por constructura"}
                labels={["Marval","Amarilo","Conisa", "Bolivar"]}
                serie={[3,5,8, 67]}
              />
            </Row>
          </Col>
        </Row>
      </Col>
      <Col style={{ padding: "1rem" }}>
        <Row gutter={15} style={{ display: "flex" }}>
          <Col span={9}>
            <PayChart
              title={"Descargas Activida edificadora"}
              labels={["IEA", "CENSO"]}
              serie={[15, 45]}
            />
          </Col>
          <Col span={15}>
            <Row style={{ minWidth: "100%"}}>
              <div style={{background:"white",padding:"1rem", borderRadius:"0.1rem", borderRadius:"0.5rem"}}>
               <p style={{marginBottom:"0.5rem", fontSize:"14px", fontWeight:"800",fontFamily:"Helvetica"}}>
                  Usuarios y constructoras
              </p>  
              <DataTable/>
              </div>
            </Row>
          </Col>
        </Row>
      </Col> */}
    </Layout>
  );
};

export default Dashboard;
