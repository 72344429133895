import React, { useEffect, useState } from "react";
import Layout from "../../layouts/home_layout/layout";
import { Affix, Button, Col, Icon, message, Row, Table, Tooltip } from "antd";

import { LineChart, MixBar, PieChart } from "../../components/com/charts";

import {
  configurationsService,
  upliftingActivityResources,
} from "../../services/services";
import "./asesoria-layout.css";

import styled from "styled-components";
import SearchForm from "./SearchForm";
import {
  getAverageUnitsSold,
  getEntities,
  getNewHomes,
  getNewHousingReleases,
  getProjectsJoinCoordeUrban,
  getSaleHousingByPriceRange,
  getTop10ProjectsHigherSale,
  getUnitiesPhase,
  getUserInfo
} from "../../services";
import { decimal, moment } from "../../utils/Helper";
import { useSelector } from "react-redux";
import { S3_INSTANCE_CAMACOL, URL_EXPORT_EXCEL } from "../../utils/Constants";
// import { Container } from "react-bootstrap/lib/Tab";

const excludes = [
  "idproyecto",
  "longitud",
  "latitud",
  "codigo_proyecto",
  "regional",
  "direccion_descriptiva",
  "tiene_sala_ventas",
  "direccion_sala_ventas",
  "idetapa, codigo_etapa",
  "area_vendible_nr",
  "area_construida_nr",
  "estado_mes_actual",
  "fase_mes_actual",
  "compania_vendedora",
  "nit_vendedora",
  "direccion_vendedora",
  "telefono_vendedora",
  "compania_que_gerencia",
  "nit_gerencia",
  "direccion_gerencia",
  "telefono_gerencia",
  "modalidad",
  "idtipo",
  "precioenmiles_mes_actual",
  "preciomc_mes_actual",
  "ventas_mes_actua",
  "renuncias_mes_actual",
  "saldo_mes_actual",
  "novedad_mes_actual",
  "depósito",
  "baterías_de_baños",
  "oficina_adicional",
  "mezzanine",
];
const MAX_WIDTH = 20;

const Box = styled.div`
  margin: 4px 0px;
  padding: 4px 10px;
  width: auto !important;
  max-width: 100% !important;
  background-color: #fff;
  overflow: hidden;
  -webkit-box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.1);
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.1);
`;

const HeadText = styled.div`
  margin: 10px;
  font-size: 20px;
  line-height: 20px;
  color: #013f88;
  text-align: center !important;
`;

const MAX_LIMIT = 10;

const List = ({
  dataSource = [],
  renderItem,
  valueField = "value",
  displayField = "name",
}) => {
  // const colors = [
  // 	"rgba(255,255,0,.397)",
  // 	"rgba(0,0,255,.397)",
  // 	"rgba(255,0,0,.397)",
  // ];
  let span = Math.round(24 / dataSource.length);
  renderItem =
    renderItem ||
    function (record = {}, index) {
      return (
        <Col key={index} span={span}>
          <div>
            <div
              style={
                record.style || {
                  background: "#013f88",
                  padding: "4px 10px",
                  width: "100%",
                }
              }
            >
              <h3
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  marginBottom: 0,
                }}
              >
                {record[displayField]}
              </h3>
            </div>
            <div
              style={{
                background: "#fff",
                fontWeight: "bold",
              }}
            >
              <h1
                style={{
                  color: "#013f88",
                  fontSize: 30,
                  fontWeight: "bold",
                  marginTop: "0.5em",
                }}
              >
                {record[valueField]}
              </h1>
            </div>
          </div>
        </Col>
      );
    };

  return (
    <Row type="flex" justify="space-around" align="top" gutter={16}>
      {dataSource.map((record, index) => {
        return renderItem(record, index);
      })}
    </Row>
  );
};


const ResourceItem = ({ data }) => {

  const handleClick = (event) => {
    // event.preventDefault(); // Evitar que el navegador siga el enlace
		getUserInfo().then((user) => {
			const {company: { nit, name }, first_name } = user; 

			const nuevoEvento = {
				constructora: "Marval_Test", 
				nombre_afiliado: "name",
				nit_company: "0",
				event: "file_download",
			};

			nuevoEvento.constructora = name;
			nuevoEvento.nombre_afiliado = first_name;
			nuevoEvento.nit_company = nit; 
      
			window.dataLayer.push(nuevoEvento); 
		});
  };

  return (
    <Button className="btn-green">
    <a className="btn-succuffly"
    href={
      data.type === "file"
        ? `${S3_INSTANCE_CAMACOL}${data.path}`
        : data.path
    }
       // Este atributo indica al navegador que debe descargar el archivo en lugar de navegar a él
      onClick={handleClick} // Agregar el manejador de eventos onClick
    >
     {data.description}
    </a>
    </Button>
  );
};

const AsesoriaComercial = (props) => {
  const user = useSelector(({ data }) => data.user);
  const [homeSalesByYear, setHomeSalesByYear] = useState([]);
  const [homeSalesByYearMonth, setHomeSalesByYearMonth] = useState([]);
  const [totalTop10, seTotalTop10] = useState(0);
  const [top10ProjectsHigherSales, setTop10ProjectsHigherSales] = useState([]);
  const [graphicsAverageUnitsSol, setAverageUnitiesSold] = useState([]);
  const [totalUnitiesYearRanges, setTotalUnitiesYearRanges] = useState(0);
  const [graphicsSalHousingByPriceRange, setSalHousingByPriceRange] = useState(
    []
  );
  const [
    graphisHousingUnitsConstructionPhase,
    setGraphisHousingUnitsConstructionPhase,
  ] = useState([]);

  const [
    creditEntitiesConstructionCompany,
    setCreditEntitiesConstructionCompany,
  ] = useState([]);

  const [fiduciaryEntities, setFiduciaryEntities] = useState([]);
  const [loading_projects, setLoadingProjects] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: MAX_LIMIT,
  });
  const [projects, setProjects] = useState([]);
  const [graphicsNewHousingReleases, setNewHousingReleases] = useState([]);
  const [cuenta, setCuenta] = useState("Ventas");
  const [show, setShow] = useState(false);
  const [payloads, setPayloads] = useState(false);
  const [titles, setTitles] = useState([]);
  const [infoText, setInfoText] = useState("");
  const [resources, setResources] = useState([]);

  //obtener las 10 mejores ventas
  const getTop10HigherSales = (filters = {}) => {
    getTop10ProjectsHigherSale(filters)
      .then((response) => {
        let { top10ProjectsHigherSales } = response;

        let total = 0;
        top10ProjectsHigherSales.reduce((prev, current) => {
          total += current.unities;
          return total;
        }, total);

        seTotalTop10(total);
        setTop10ProjectsHigherSales(top10ProjectsHigherSales);
      })
      .catch((err) => {});
  };

  //
  const handleTableChange = (pagination, payloads, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    getProjects({
      ...payloads,
      page: pagination.current || 1,
      limit: MAX_LIMIT,
    });
  };

  //obtener viviendas de unidad
  const getNewUnitiesHomes = (filters = {}) => {
    getNewHomes(filters)
      .then((response) => {
        let { homeSalesByYear, homeSalesByYearMonth } = response;
        setHomeSalesByYear(homeSalesByYear);
        homeSalesByYearMonth = homeSalesByYearMonth.map((item) => {
          item["year_month"] = moment(
            `${item.month < 10 ? 0 + "" + item.month : item.month}${item.year}`,
            "MMYYYY"
          ).format("MMM YYYY");
          return item;
        });
        setHomeSalesByYearMonth(homeSalesByYearMonth);
      })
      .catch((err) => {});
  };

  //obtener Unidades Promedio Vendidas
  const getAverageUnitiesSold = (filters = {}) => {
    getAverageUnitsSold(filters)
      .then((response) => {
        let { graphicsAverageUnitsSoldYearMonth } = response;
        if (graphicsAverageUnitsSoldYearMonth) {
          let {
            average,
            // year,
            // month,
            // rango_area,
            // rango_ppm2,
          } = graphicsAverageUnitsSoldYearMonth;

          let data = [];
          data.push({
            value: decimal(average["$numberDecimal"], {
              precision: 2,
            }),
            name: "Promedio Unidades",
          });
          /* data.push({
					  value: rango_area,
					  name: "Rango de área"
					});
					data.push({
					  value: rango_ppm2,
					  name: "Rango de precio"
					}); */

          setAverageUnitiesSold(data);
        }
      })
      .catch((err) => message.error(err.message));
  };

  //obtener gráfico de entidades
  const getEntitiesChart = (filters = {}) => {
    getEntities(filters)
      .then((response) => {
        let { creditEntitiesConstructionCompany, fiduciaryEntities } = response;

        creditEntitiesConstructionCompany = creditEntitiesConstructionCompany.map(
          (item) => {
            return {
              name: item.entidad,
              value: item.count,
            };
          }
        );
        fiduciaryEntities = fiduciaryEntities.map((item) => {
          return {
            name: item.entidad,
            value: item.count,
          };
        });
        setCreditEntitiesConstructionCompany(creditEntitiesConstructionCompany);
        setFiduciaryEntities(fiduciaryEntities);
      })
      .catch((err) => {});
  };

  //obtener viviendas en venta por rango de precios
  const getSalesHousingByPriceRange = (filters = {}) => {
    getSaleHousingByPriceRange(filters)
      .then((response) => {
        let { graphicsSalHousingByPriceRange } = response;
        let total = 0;
        graphicsSalHousingByPriceRange.forEach((it) => {
          let { projects } = it;
          total = projects.reduce((prev, current) => {
            return total + current.unities;
          }, total);
          setTotalUnitiesYearRanges(total);
        });
        setSalHousingByPriceRange(graphicsSalHousingByPriceRange);
      })
      .catch((err) => {});
  };

  //Proyectos
  const getProjects = (filters = {}) => {
    setLoadingProjects(true);
    getProjectsJoinCoordeUrban({
      ...filters,
      page: filters["page"] || pagination.current,
      limit: MAX_LIMIT,
    })
      .then((response) => {
        let { data, total, limit, page } = response;

        setPagination({
          total,
          page,
          limit,
          data,
        });
        setProjects(data);
        setLoadingProjects(false);
      })
      .catch((err) => {});
  };

  const getUnitiesByPhases = (filters = {}) => {
    getUnitiesPhase(filters)
      .then((response) => {
        let { graphisHousingUnitsConstructionPhase } = response;

        graphisHousingUnitsConstructionPhase = graphisHousingUnitsConstructionPhase.map(
          (item) => {
            return {
              name: item.fase,
              value: item.unities,
            };
          }
        );

        setGraphisHousingUnitsConstructionPhase(
          graphisHousingUnitsConstructionPhase
        );
      })
      .catch((err) => {});
  };

  //obtener Unidades Por Fases
  const getProjectsNews = (filters = {}) => {
    getNewHousingReleases(filters)
      .then((response) => {
        let { graphicsNewHousingReleases } = response;
        setNewHousingReleases(graphicsNewHousingReleases);
      })
      .catch((err) => {});
  };



  //Obtener servicios de configuración
  const getConfigurationsServices = async () => {
    await configurationsService
      .find({
        query: {
          key: {
            $in: [
              "UPLIFTING_ACTIVITY_GRAPHIC_TITLE_1",
              "UPLIFTING_ACTIVITY_GRAPHIC_TITLE_2",
              "UPLIFTING_ACTIVITY_INFO",
            ],
          },
          status: "active",
        },
      })
      .then(({ data }) => {
        if (data.length === 3) {
          setTitles([data[0].value, data[1].value]);
          setInfoText(data[2].value);
        } else {
          setTitles([data[0].value, data[1].value]);
        }
      });
  };

  //obtener recursos
  const getResources = async () => {
    await upliftingActivityResources
      .find({
        query: {
          status: "active",
          $sort: { position: 1 },
        },
      })
      .then(({ data }) => {
        setResources(data);
      });
  };

  //buscar datos dinámicos
  const fetchDynamicData = async () => {
    await Promise.all([getConfigurationsServices(), getResources()]);
  };

  //buscar
  const search = (payloads) => {
    getTop10HigherSales(payloads);
    getNewUnitiesHomes(payloads);
    getAverageUnitiesSold(payloads);
    if (show) {
      getEntitiesChart(payloads);
      getSalesHousingByPriceRange(payloads);
      getUnitiesByPhases(payloads);
      getProjectsNews(payloads);
    }
    getProjects({
      ...payloads,
      page: pagination.current || 1,
      limit: MAX_LIMIT,
    });
  };

  //
  const handleChange = (payloads) => {
    /* alert(JSON.stringify(payloads)); */
    setPayloads(payloads);
    if (payloads.cuenta) setCuenta(payloads.cuenta);
    //console.log(">>>>OnChange: ", payloads);
  };

  const handleSubmit = (err, payloads) => {
    if (!err) setPayloads(payloads);
  };

  useEffect(() => {
    fetchDynamicData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log({ resources });
  }, [resources]);

  useEffect(() => {
    if (user) {
      let { company } = user;
      if (company) setShow(company.building_activity === "active");
      search(payloads);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, payloads]);

//   console.log({ infoText });

  return (
    <Layout>
      <Row
        id="22"
        align="top"
        gutter={0}

        style={{
          textAlign: "center",
          height: "100%",
		  padding:"2rem"
        }}
      >
		
        {/* xl={MAX_WIDTH} lg={24} md={24} xs={24} */}
        {/* ----------------------------------- BARRA DE FILTROS --------------------------------- */}
        {/* ----------------------------------- BARRA DE FILTROS --------------------------------- */}

        <Col>
          <Box>
            <SearchForm
              layout="horizontal"
              onSubmit={handleSubmit}
              onChange={handleChange}
              show={show}
            />
          </Box>
        </Col>
        {/* ----------------------------------- BARRA DE FILTROS --------------------------------- */}
        {/* ----------------------------------- BARRA DE FILTROS --------------------------------- */}


		  {/* ------------------------------------- DESCARGAS  ----------------------------------------*/}
		  {!!resources.length && (
					<Row>
						<HeadText>Descarga</HeadText>
						{resources.map((resource, i) => <Col span={12}>
						<ResourceItem data={resource}  key={`resource-item-${i}`} />
						</Col>)}
					</Row>
				)}
				
        {/* xl={MAX_WIDTH - 4} lg={24} md={24} xs={24} */}

        <Col style={{marginBottom:"1rem"}}>
          {/* gutter={50} type="flex" justify="start" align="top" */}
          <Row gutter={20}>
            {/* <Col align="left" span={24}>
							<HeadText>
								{cuenta} de vivienda nueva en el dpto del Atlántico
							</HeadText>
			            </Col>*/}
            {/* align="center" xl={12} lg={12} md={24} xs={24} */}
            {/* align="center" xl={12} lg={12} md={24} xs={24} */}

			

			{console.log(resources && resources)}

            <Col span={16}>
              {/* -------------------------- GRAFICO DE LINEA  ------------------------- */}
              <div className="dashboard-gafico-lineal">
                <HeadText style={{margin:".5rem"}}>{titles[1]}</HeadText>
                <LineChart
                  xaxis="year_month"
                  lines={[
                    {
                      key: "vis",
                    },
                    {
                      key: "no_vis",
                    },
                  ]}
                  data={homeSalesByYearMonth}
                />
              </div>
              {/* {console.log(homeSalesByYearMonth && homeSalesByYearMonth)} */}
              {/* ------------------------------- GRAFICO DE LINEA --------------------------*/}
            </Col>

            <Col span={8}>
              <div className="dashboard-gafico-barras">
                <HeadText style={{margin:".5rem"}}>{titles[0]}</HeadText>
                <MixBar
                  xaxis="year"
                  axis={[
                    {
                      key: "vis",
                    },
                    {
                      key: "no_vis",
                    },
                  ]}
                  data={homeSalesByYear}
                />
              </div>
            </Col>
          </Row>
        </Col>

      
        {/* ------------------------------------- DESCARGAS  ----------------------------------------*/}

        {/*----------------------------------- PROYECTO DOS CAJAS------------------------------- */}
        {show && cuenta === "Ventas" && (
          <Col style={{marginBottom:"1rem"}}>
            <Row gutter={20}>
              <Col span={12}>
                <Box>
                <HeadText style={{margin:".5rem"}}>Proyectos por credito constructor</HeadText>
                  <PieChart data={creditEntitiesConstructionCompany} />
                </Box>
              </Col>

              <Col span={12}>
                <Box>
                <HeadText style={{margin:".5rem"}}>Proyectos por entidad fiduciaria</HeadText>
                  <PieChart data={fiduciaryEntities} />
                </Box>
              </Col>
            </Row>
          </Col>
        )}
        {/*----------------------------------- PROYECTO DOS CAJAS------------------------------- */}

        {/* ------------------------------------------ TABLA DATOS ---------------------------- */}
        <Col style={{marginBottom:"1rem"}}>
          <Row gutter={20} type="flex" justify="start" align="top">
            <Col span={24}>
              <Box>
                <Table
                  className="table-small"
                  size="small"
                  pagination={false}
                  dataSource={top10ProjectsHigherSales}
                  scroll={{
                    x: 200,
                    y: 400,
                  }}
                  footer={() => (
                    <Row type="flex" justify="end" gutter={16}>
                      <Col>
                        <span>
                          <strong>Total:</strong>
                        </span>
                      </Col>
                      <Col>
                        <div
                          style={{
                            margin: "0px auto",
                          }}
                        >
                          {decimal(totalTop10)}
                        </div>
                      </Col>
                    </Row>
                  )}
                  columns={[
                    {
                      dataIndex: "name",
                      title: "Proyecto",
                      width: 250,
                    },
                    {
                      dataIndex: "construction_company",
                      title: "Constructora",
                      width: 250,
                    },
                    {
                      dataIndex: "neighborhood",
                      title: "Barrio",
                      width: 250,
                    },
                    {
                      dataIndex: "stratus",
                      title: "Estrato",
                      width: 150,
                    },
                    {
                      dataIndex: "unities",
                      title: "Unidades",
                      width: 150,
                    },
                  ]}
                />
              </Box>
            </Col>
            {cuenta === "Ventas" && (
              <Col
                span={24}
                style={{
                  margin: "20px 0px",
                }}
              >
                <List dataSource={graphicsAverageUnitsSol} />
              </Col>
            )}
          </Row>
        </Col>
        {/* ------------------------------------------ TABLA DATOS ---------------------------- */}

        {show && cuenta === "Oferta" && (
          <Col style={{marginBottom:"1rem"}}>
            <Row >
              <Col>
                <Box>
				<HeadText style={{margin:".5rem"}}>
                  Unidades de vivienda por Fase constructiva en el Atlántico
                </HeadText>
                  <div className="radius-chart">
                    <PieChart data={graphisHousingUnitsConstructionPhase} />
                  </div>
                </Box>
              </Col>
              <Col>
               
                <Box>
				<HeadText style={{margin:".5rem"}}>
                  Lanzamiento de vivienda nueva en el dpto del Atlántico
                </HeadText>
                  <Table
                    pagination={false}
                    columns={[
                      {
                        title: "Proyecto",
                        dataIndex: "nombre_proyecto",
                      },
                      {
                        title: "Tipo",
                        dataIndex: "etapa",
                      },
                      /*  {
										   title: "Tipo",
										   dataIndex: "tipo_vivienda"
										 }, */
                      /*  {
										   title: "Area por tipo ",
										   dataIndex: "area_por_tipo"
										 }, */
                      {
                        title: "Barrio",
                        dataIndex: "barrio",
                      },
                      {
                        title: "Estrato",
                        dataIndex: "estrato",
                      },
                      {
                        title: "Compañia constructora",
                        dataIndex: "compania_constructora",
                      },
                      {
                        title: "Compañia vendedora",
                        dataIndex: "compania_vendedora",
                      },
                      {
                        title: "Oferta disponible",
                        dataIndex: "numero_unidades",
                      },
                    ]}
                    className="table-small"
                    scroll={{ x: 1500, y: 400 }}
                    size="small"
                    dataSource={graphicsNewHousingReleases}
                  />
                </Box>
              </Col>
            </Row>
          </Col>
        )}

        {show && (
          <Col style={{marginBottom:"1rem"}}>
            <Row gutter={20}>
              <Col span={24}>
                <HeadText>
                  Ventas de vivienda en el Atlántico por rango de precios
                </HeadText>
                <Box>
                  <Table
                    className="table-small table-without-title"
                    loading={graphicsSalHousingByPriceRange.length === 0}
                    /* scroll={{ x: true }} */ table-without-title
                    size="small"
                    pagination={false}
                    dataSource={graphicsSalHousingByPriceRange}
                    footer={() => (
                      <Row>
                        <Col>
                          <span>
                            <strong>Total:</strong>
                          </span>
                        </Col>
                        <Col>
                          <div
                            style={{
                              margin: "0px auto",
                            }}
                          >
                            {decimal(totalUnitiesYearRanges)}
                          </div>
                        </Col>
                      </Row>
                    )}
                    expandedRowRender={(record) => {
                      let { projects } = record;
                      // let columns = [];
                      /* if (projects.length > 0) {
										  let project = projects[0];
										  Object.keys(project).forEach(key => {
											if (key !== "_id")
											  columns.push({
												dataIndex: key,
												width: key.length > 10 ? key.length * 10 : 150,
												title: key.replace(/\_/, " ").toUpperCase()
											  });
										  });
										} */
                      return (
                        <Table
                          className="table-small"
                          size="small"
                          pagination={false}
                          columns={[
                            {
                              title: "Año",
                              dataIndex: "year",
                              key: "year",
                              //width: 450,
                            },
                            {
                              title: "Total Unidades",
                              dataIndex: "unities",
                              key: "building",
                              //width: 450,
                            },
                          ]}
                          dataSource={projects}
                        />
                      );
                    }}
                    columns={[
                      {
                        title: "Rango de precios",
                        dataIndex: "_id",
                        key: "_id",
                        //width: 450,
                      },
                    ]}
                  />
                </Box>
              </Col>
            </Row>
          </Col>
        )}

        {show && (
          <Col style={{marginBottom:"1rem"}}>
            <HeadText>
              Base de Proyectos en el Departamento del Atlántico
            </HeadText>
            <Box>
              <Table
                className="table-small"
                scroll={{ x: 1500, y: 400 }}
                size="small"
                pagination={pagination}
                onChange={handleTableChange}
                dataSource={projects}
                loading={loading_projects}
                expandedRowRender={(record) => {
                  let { projects } = record;
                  let columns = [];
                  if (projects.length > 0) {
                    let project = projects[0];

                    let keys = Object.keys(project).filter(
                      (key) => !excludes.includes(key)
                    );
                    keys.forEach((key) => {
                      if (key !== "_id")
                        columns.push({
                          dataIndex: key,
                          width: key.length > 10 ? key.length * 10 : 150,
                          title: key.replace(/\\_/, " ").toUpperCase(),
                        });
                    });
                  }
                  return (
                    <Table
                      scroll={{ x: 500 }}
                      size="default"
                      className="inner-table"
                      pagination={false}
                      columns={columns}
                      dataSource={projects}
                    />
                  );
                }}
                columns={[
                  {
                    title: "Proyecto",
                    dataIndex: "projects.0.nombre_proyecto",
                    width: "100%",
                  },
                ]}
              />
            </Box>
          </Col>
        )}

        {!!infoText && (
          <Col style={{marginBottom:"1rem"}}>
            <Box style={{ marginLeft: 10, borderRadius: 8, padding: 10 }}>
              <p style={{ margin: 0 }}>{infoText}</p>
            </Box>
          </Col>
        )}
      </Row>

      <Affix
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
        }}
      >
        <Tooltip title="Descargar Excel">
          <Button
            size="large"
            style={{
              margin: 20,
            }}
            className="btn-green"
            shape="circle"
          >
            <a
              href={URL_EXPORT_EXCEL}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon size="large" type="file-excel" />
            </a>
          </Button>
        </Tooltip>
      </Affix>
    </Layout>
  );
};

export default AsesoriaComercial;
