import React from "react";
import ReactApexChart from "react-apexcharts";

class PayChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.serie || [0,1],
      options: {
        chart: {
          type: 'donut',
        },
        legend: {
          position: 'bottom', // Coloca la leyenda debajo del gráfico
          horizontalAlign: 'center', // Centra la leyenda
        },
        title:{
          text:this.props.title
        },
        labels:this.props.labels || ['team-1', 'team-2'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 600,
              },
              legend: {
                position: 'bottom', // Asegura que la leyenda esté debajo en pantallas pequeñas también
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <div>
        <div
          id="chart"
          style={{
            background: "white",
            padding: "1rem",
            borderRadius: "0.5rem",
          }}
        >
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="donut"
            width={380}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default PayChart;
