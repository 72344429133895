import React, { useEffect } from "react";
import { Col, Layout, Row } from "antd";
import "./homelayout.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { menuService } from "../../services/services";
import { setMenuHeader } from "../../redux/actions";

const { Content } = Layout;

const HomeLayout = ({ className, children, contanierClassName }) => {

	const menus = useSelector(state => state.ui.menus) || []
	const dispatch = useDispatch()

	const getMenus = async () => {
		await menuService.find({ query: { status: 'active', $sort: { position: 1 } } })
			.then(({ data }) => {
				dispatch(setMenuHeader(data))
			})
	}

	useEffect(() => {
		if (!menus.length) {
			console.log('entre')
			getMenus()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menus])

	return (
		<Layout className={className ? className : "home-layout"}>
			<Header />
			<Row
				type="flex"
				justify="center"
				align="middle"
				gutter={{
					xl: 18,
				}}
				className={`${contanierClassName ? contanierClassName :  "home-content"} bg-main-container`}
			>
				<Col className={contanierClassName ? contanierClassName : "home-container"} span={19}>
					<Row type="flex" justify="center" align="middle">
						<Col>{children}</Col>
					</Row>
				</Col>
			</Row>
			<Footer />
		</Layout>
	)
};
export default HomeLayout;
