import React, { useState, useEffect } from 'react'

import { Button, Card, Icon, Row, Typography, Col, message } from 'antd';
import Layout from "../../layouts/home_layout/homelayout";
import { S3_INSTANCE_CAMACOL } from "../../utils/Constants"
import { getSingleConstructionProcedures, getCallSendEmailConstructionProcedures } from "../../services/index"

import "../asesoria_comercial/asesoria-layout.css";
import "./visualizavcionPdf.css"
import "../asesoria_comercial/asesoria-layout.css"
import { useSelector } from 'react-redux';

const { Paragraph } = Typography;

const VisualizacionPdf = ({ match }) => {
    const [pdfFile, setPdfFile] = useState();
    const { id } = match.params
    const user = useSelector(({ data }) => data.user)

    console.log({
        user
    })

    useEffect(() => {

        if (id) {
            getSingleConstructionProcedures(id).then((res) => {
                console.log(res.data[0])
                setPdfFile(res.data[0])
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, match.params]);

    const sendEmail = (data) => {
        getCallSendEmailConstructionProcedures({
            username: `${user.first_name} ${user.last_name}`,
            phone: user.phone_number,
            email: user.email,
            company_name: user.company.name,
            contact_email: data.contact_email,
            city_name: data.city_name,
        })
            .then(() => {
                message.success('Se ha enviado una notificación de contacto')
            })
    }

    return (
        <Layout style={{ margin: '0 0 0 0 !important' }} className="asesoria-layout"
            contanierClassName="visualizadorPdf-container"
        >
            <Row
                id="22"
                type="flex"
                justify="center"
                align="top"
                gutter={0}
                style={{
                    textAlign: "center",
                    width: '92vw',
                    maxWidth: '1440px',
                    height: "83vh",
                }}
            >
                <Col
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // width: '65%',
                        height: '100%',
                    }}
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                >
                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {pdfFile && (
                            <object data={
                                `${S3_INSTANCE_CAMACOL}${pdfFile.preview_pdf_path
                                }`} type="application/pdf" width="100%" height="100%">
                                <p>Alternative text - include a link <a href={pdfFile.preview_pdf_path}>to the PDF!</a></p>
                            </object>
                        )}

                    </div>
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // width: '35%',
                        height: '100%',
                        // borderRadius: '0 15px 15px 0',
                        backgroundColor: '#f1f2f3',
                    }}
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                >
                    <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h1 className="home-title">
                            {
                                pdfFile && (
                                    <>
                                        Trámites para la construcción<br /><span className="text-primary">{pdfFile.city_name}</span>
                                    </>
                                )
                            }
                        </h1>
                    </div>
                    <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button size="large" className="btn-green">
                            {pdfFile && (
                                <a href={`${S3_INSTANCE_CAMACOL}${pdfFile.download_file_path}`} target="_blank" rel="noopener noreferrer">
                                    <Icon size="large" type={pdfFile.icon_path} />
                                    &nbsp;
                                    Descargar
                                </a>
                            )}
                            {/* <a href={pdfFile.type === 'file' ? `${S3_INSTANCE_CAMACOL}${pdfFile.download_file_path}` : pdfFile.download_file_path} target="_blank" rel="noopener noreferrer">
                                <Icon size="large" type={pdfFile.icon_path} />
                                &nbsp;
                                Descargar
                            </a> */}
                        </Button>
                    </div>
                    <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'column', height: '450px', }}>
                        <Card
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '92%',
                                textAlign: 'justify',
                                margin: '4px 4px',
                                padding: '4px 4px',
                                borderRadius: '10px',
                                flex: '1 1 auto',
                            }}
                        >
                            {pdfFile && (
                                <Paragraph style={{ fontSize: '13px', width: '100%', flex: '1 1 auto', height: '86%', overflow: 'auto' }}>
                                    {pdfFile.observation}
                                </Paragraph>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    flex: '1 1 auto',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <Button onClick={() => sendEmail(pdfFile)} size="large" className="btn-green">
                                    {pdfFile && (
                                        //  <a href={`mailto:${pdfFile.contact_email}?subject=SendMail&body=Description`} target="_blank" rel="noopener noreferrer">
                                        // </a>
                                        <>
                                            <Icon size="large" type='mail' />
                                            &nbsp;
                                            Contactar
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Layout>
    )
}

export default VisualizacionPdf;