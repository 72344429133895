import React from "react";
import { Col, Layout, Row } from "antd";
import "./login_layout.css";

const Logo = require("../../resources/images/logo-camacol.png");
const LoginLayout = ({ children }) => (
	<Layout className="login-layout">
		<Row>
			<Col xs={24} xl={12} md={24} lg={12} className="login-col-left" span={12}>
				<Row type="flex" justify="start" align="top">
					<Col xs={24} xl={24} className="logo-login-container" span={4}>
						<a href="/">
							<img
								alt="Camacol Barranquill"
								className="logo-login"
								src={Logo}
							/>
						</a>
					</Col>
				</Row>
			</Col>
			<Col
				xs={24}
				xl={12}
				md={24}
				lg={12}
				className="login-col-right"
				span={12}
			>
				<Layout className="login-container">{children}</Layout>
			</Col>
		</Row>
	</Layout>
);
export default LoginLayout;
