import React, { useEffect, useState } from "react";
import Form from "../../components/com/form/AdvancedForm";
import { Input, message } from "antd";
import { SelectField, ToggleField } from "../../components/com";
import { getContactDepartaments, getContactPositions } from "../../services";

/*
contact_deparment_id,
first_name,
last_name,
email,
phone_number,
mobile_phone_number,
contact_position_id,
notes,
*/
const validator = [
	{
		required: true,
	},
];
const CompanyContact = (props) => {
	const [contact_positions, setContactPositions] = useState([]);
	const [contact_deparments, setContactDeparments] = useState([]);

	let {
		id,
		contact_deparment_id,
		first_name,
		last_name,
		email,
		phone_number,
		mobile_phone_number,
		contact_position_id,
		notes,
		show_affiliates_directory,
		onSubmit,
	} = props;

	useEffect(() => {
		getContactDepartaments()
			.then((response) => {
				let { data } = response;
				setContactDeparments(data);
			})
			.catch((err) => message.error(err.message));
		getContactPositions()
			.then((response) => {
				let { data } = response;
				setContactPositions(data);
			})
			.catch((err) => message.error(err.message));
		return () => {};
	}, []);
	return (
		<Form onSubmit={onSubmit}>
			<div>
				<Input type="hidden" name="id" initialValue={id} />
				<Input
					size="large"
					initialValue={first_name}
					name="first_name"
					label="Nombres"
					placeholder="Nombres"
				/>
				<Input
					size="large"
					initialValue={last_name}
					name="last_name"
					label="Apellidos"
					placeholder="Apellidos"
				/>
				<Input
					size="large"
					type="email"
					name="email"
					initialValue={email}
					label="Email"
					validations={[{ required: true }]}
					placeholder="Email"
				/>
				<Input
					size="large"
					initialValue={phone_number}
					name="phone_number"
					label="Número telefónico"
					placeholder="Número telefónico"
				/>
				<Input
					size="large"
					initialValue={mobile_phone_number}
					name="mobile_phone_number"
					label="Celular"
					placeholder="Celular"
				/>
				<SelectField
					initialValue={contact_position_id}
					dataSource={contact_positions}
					name="contact_position_id"
					label="Cargo"
					placeholder="Cargo"
				/>
				<SelectField
					initialValue={contact_deparment_id}
					dataSource={contact_deparments}
					name="contact_deparment_id"
					label="Departamento"
					placeholder="Departamento"
					style={{
						width: "100%",
					}}
				/>

				<Input.TextArea
					style={{
						width: "100%",
					}}
					name="notes"
					initialValue={notes}
					label="Notas"
				/>
				<ToggleField
					name="show_affiliates_directory"
					initialValue={show_affiliates_directory}
					label="Publicar para Directorio de Afiliados"
					style={{
						width: "100%",
						float: "right",
					}}
				/>
			</div>
		</Form>
	);
};

export default CompanyContact;
