import feathers from "@feathersjs/client";
import { URL_AUTHENTICATION, URL_BASE } from "./Constants";

const api = feathers();
api.configure(feathers.rest(URL_BASE).fetch(window.fetch.bind(window)));
api.configure(
	feathers.authentication({
		path: URL_AUTHENTICATION,
		entity: "user",
		service: "users",
		cookie: "feathers-jwt",
		storageKey: "feathers-jwt",
		storage: window.localStorage,
	})
);
export default api;
