import React from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

const data = [
	{
		name: "2019",
		uv: 4000,
		pv: 10.3,
		amt: 3.2,
	},
];
const colors = ["#9B3C84", "#1B2362"];
const MixBar = ({ size = 350, ...props }) => {
	return (
		<BarChart
			width={size + 60}
			height={size + 50}
			data={props.data || data}
			margin={{
				top: 20,
				right: 0,
				left: -20,
				bottom: 20,
			}}
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey={props.xaxis || "year"} />
			<YAxis da />
			<Tooltip />
			{/*  <Legend /> */}
			{props.axis.map((item, index) => (
				<Bar
					dataKey={item.key}
					stackId="a"
					fill={item.color || colors[index]}
				/>
			))}
			{/* <Bar dataKey="pv" stackId="a" fill="#9B3C84" />
      <Bar dataKey="amt" stackId="a" fill="#1B2362" />
       */}
			{/* <Bar dataKey="uv" fill="#ffc658" /> */}
		</BarChart>
	);
};

export default MixBar;
