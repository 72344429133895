import axios from "axios";
import { URL_PANORAMA_UBANO} from "../constants/"
const Api = axios.create({ 
    baseURL: URL_PANORAMA_UBANO,
    /* withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    } */
});

export default Api;
