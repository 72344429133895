import React, { Component } from "react";
import { Divider, Input } from "antd";
import AdvancedForm from "../../components/com/form/AdvancedForm";
import FieldUploader from "../../components/com/form/S3Uploader";
import {
	company_service,
	groupsOption,
	segmentationOptions,
	typeAffiliates,
} from "../../services";

import { CheckBoxGroup, SelectField } from "../../components/com";

/*
address,
affiliate_status_id,
createdAt,
deletedAt,
email,
fix_phone_number,
group_id,
id,
location_city_id,
logo,
management_fee,
mobile_phone_number,
name,
nit,
type_affiliate_id,
updatedAt,
website,
*/
class CompanyForm extends Component {
	state = {
		type_affiliates: [],
		segmentation: [],
		groups: [],
	};
	componentWillMount() {
		let self = this;
		typeAffiliates().then((response) => {
			let { data } = response;
			self.setState({
				type_affiliates: data,
			});
		});
		groupsOption().then((response) => {
			let { data } = response;
			self.setState({
				groups: data,
			});
		});
		segmentationOptions().then((response) => {
			let { data } = response;
			self.setState({
				segmentation: data,
			});
		});
	}

	ref = React.createRef();

	onFinish = (url, signResult, file) => {
		company_service
			.patch(this.props.data.id, { logo: signResult })
			.then((rest) => { })
			.catch((err) => { });
	};

	render() {
		let { data = {}, onSubmit } = this.props;
		let {
			address,
			affiliate_status_id,
			createdAt,
			deletedAt,
			email,
			fix_phone_number,
			group_id,
			id,
			location_city_id,
			logo,
			management_fee,
			mobile_phone_number,
			name,
			nit,
			type_affiliate_id,
			companies_segmentations_ids,
			updatedAt,
			website,
		} = data;
		let { type_affiliates, groups, segmentation } = this.state;

		return (
			<AdvancedForm
				ref={this.ref}
				onSubmit={this.props.onSubmit}
				autoSubmit={true}
			>
				<div>
					<FieldUploader
						onChange={this.onFinish}
						onDelete={this.props.onDelete}
						labelLogo={"Las medias recomendadas son de 240 x 240 px"}
						name="logo"
						initialValue={logo}
						path="buy-camacol/"
						style={{
							width: "100%",
							margin: "0px auto",
						}}
					/>

					<Divider
						dashed
						style={{
							width: "100%",
							margin: 0,
						}}
					/>
					<Input type="hidden" name="id" initial={id} />
					<Input initial={name} name="name" label="Razón Social" />
					<Input initial={nit} name="nit" label="Nit" />
					<Input initial={address} name="address" label="Dirección" />
					<Input
						initial={mobile_phone_number}
						name="mobile_phone_number"
						label="Celular"
					/>
					<Input
						initial={fix_phone_number}
						name="fix_phone_number"
						label="Teléfono"
					/>
					<Input initial={email} name="email" label="Correo electrónico" />
					<Input initial={website} name="website" label="Sitio Web" />
					{type_affiliate_id && (
						<SelectField
							loading={type_affiliates.length === 0}
							label={"Tipo Afiliado: " + type_affiliate_id}
							displayField="name"
							valueField="id"
							initialValue={type_affiliate_id}
							name="type_affiliate_id"
							dataSource={type_affiliates}
						/>
					)}
					{group_id && (
						<SelectField
							loading={groups.length === 0}
							label="Grupo"
							displayField="name"
							valueField="id"
							initialValue={group_id}
							name="group_id"
							dataSource={groups}
						/>
					)}
					{companies_segmentations_ids && (
						<CheckBoxGroup
							name="companies_segmentations_ids"
							dataSource={segmentation}
							initialValue={companies_segmentations_ids}
							placeholder="Seleccione"
							label="Segmentación"
						/>
					)}
				</div>
			</AdvancedForm>
		);
	}
}
export default CompanyForm;
