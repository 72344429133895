import React from 'react';
import CardFile from "./CardFile";
import { Card, Col, List, Row, Icon } from "antd";
import "./cardListFile.css";
import { Link } from 'react-router-dom';
import { S3_INSTANCE_CAMACOL } from "../../utils/Constants"

const { Meta } = Card;

const CardListFile = (props) => {
	console.log({ props })
	const defaultGrid = props.grid || {
		gutter: 16,
		column: props.column || 4,
		xs: 1,
		sm: 2,
		md: 2,
		lg: 4,
		xl: 4,
		xxl: 4,
	};
	let renderItem =
		props.renderItem ||
		function (record) {
			return (
				<List.Item>
					< CardFile {...record} />
				</List.Item >
			);
		};
	return (
		// <Row gutter={16}>
		// 	{
		// 		props.dataSource.map((record, idx) => (
		// 			<Col style={{ display: 'flex', width: '300px', height: '280px' }} key={idx} xs={1} sm={2} md={2} lg={4} xl={5} xxl={6}>
		// 				<Card
		// 					className="card-item"
		// 					hoverable
		// 					style={{ width: 240 }}
		// 					cover={
		// 						<div className="card-cover-container">
		// 							<Link to={`/tramites_construccion/${props.id}` || ""}>
		// 								<div
		// 									className="card-cover"
		// 									alt="example"
		// 									style={{
		// 										backgroundImage: `url(${S3_INSTANCE_CAMACOL}${props.icon_path ||
		// 											"https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
		// 											})`,
		// 									}}
		// 								></div>
		// 							</Link>
		// 						</div>
		// 					}
		// 				>
		// 				</Card>
		// 				<Meta
		// 					title={
		// 						<div className="card-title" style={{ display: 'flex', justifyContent: 'center', alignItem: 'center', marginTop: '10px', width: 240 }}>
		// 							{props.icon_path && <Icon type={props.icon_path} />}
		// 							<Link to={`/tramites_construccion/${props.id}` || ""}>
		// 								<h2>{props.city_name}</h2>
		// 							</Link>
		// 						</div>
		// 					}
		// 					description={props.description}
		// 				/>
		// 			</Col>
		// 		))
		// 	}
		// </Row>
		<List
			className={props.className || "file-card-list"}
			itemLayout={props.itemLayout || "horizontal"}
			grid={defaultGrid}
			dataSource={props.dataSource}
			renderItem={renderItem}
		></List>
	);
};
export default CardListFile;