import React, { Component } from "react";
import { Col, Row } from "antd";

/* Redux Connect */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authenticate, isLoggedIn } from "../../redux/actions";

/* Import Custom Components */
import Layout from "../../layouts/home_layout/homelayout";
import CardListFile from "../../components/card/CardListFile";
import { getConstructionProcedures } from "../../services/index"


class Tramites_construccion extends Component {
    //validador de loggin
    state = {
        fileCard: [],
    }

    componentWillReceiveProps(nextProps) {
        const { data = {}, history } = nextProps;
        if ("loggedIn" in data) if (!data["loggedIn"]) return history.replace("/");
    }

    componentWillMount() {
        getConstructionProcedures().then((res) => {
            console.log(res.data)
            this.setState({ fileCard: res.data })
        })
    }

    render() {
        return (
            <Layout>
                <div>
                    <Row>
                        <Col span={32}>
                            <h1 className="home-title">
                                Trámites para la <span className="text-primary">Construcción</span>
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={32}>
                            <CardListFile dataSource={this.state.fileCard} />
                        </Col>
                    </Row>
                </div>
            </Layout>
        )
    }
}
/* Mapping Actions to Props*/
const mapStateToProps = (state) => {
    const { data } = state;
    return {
        data,
    };
};
/* Event Action Dispatch to Props */
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ authenticate, isLoggedIn }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tramites_construccion);
