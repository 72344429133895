import React from "react";
import { Col, Layout, Row } from "antd";
import "./mainlayout.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { menuService } from "../../services/services";
import { setMenuHeader } from "../../redux/actions";
import { useEffect } from "react";

const { Content } = Layout;

/*const Logo = require("../../resources/images/logo.svg");*/

const MainLayout = ({ children, className }) => {

	const menus = useSelector(state => state.ui.menus) || []
	const dispatch = useDispatch()

	const getMenus = async () => {
		await menuService.find({
			query: { status: 'active', $sort: { position: 1 } }
		}).then(({ data }) => {
			dispatch(setMenuHeader(data))
		})
	}

	useEffect(() => {
		if (!menus.length) getMenus()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menus])

	return (
		<Layout className={className ? `main-layout ${className}` : "main-layout"}>
			<Header />
			<Content className="main-content bg-main-container">
				<Row type="flex" justify="center" align="top">
					<Col className="main-container" span={18}>
						{children}
					</Col>
				</Row>
			</Content>
			<Footer />
		</Layout>
	)
};
export default MainLayout;
