import React, { useEffect, useState } from "react";
import { Col, Icon, message, Row, Table } from "antd";
import Layout from "../../layouts/contacto_layout/contacto_layout";
import "./contacto.css";
import { getUserInfo, getService } from "../../services/";

/* const contact_list = [

  {
    key: 1,
    name: "Monica Orjuela Pereira",
    cargo: "Directora Administrativa y financiera ",
    category: "ADMNISTRATIVA Y FINANCIERA",
    phone: "3851050 Ext. 104",
    email: "morjuela@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/elliot.jpg"
  },
  {
    key: 2,
    name: "Luisa Martínez Rocha",
    cargo: "Analista Administrativo y Contable",
    //category: "ADMNISTRATIVA Y FINANCIERA",
    phone: "3851050 Ext. 117",
    email: "lmartinez@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/jenny.jpg"
  },
  {
    key: 3,
    name: "Maria de los Angeles Manjarrez Barboza",
    cargo: "Contadora",
    //category: "ADMNISTRATIVA Y FINANCIERA",
    phone: "3851050 Ext. 106",
    email: "contabilidad@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/jenny.jpg"
  },
  {
    key: 4,
    name: "Anna María Restrepo Mejía",
    cargo: "Auxiliar de Coordenada Urbana y Cartera",
    //category: "ADMNISTRATIVA Y FINANCIERA",
    phone: "3851050 Ext. 113",
    email: "arestrepo@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/molly.png"
  },
  {
    key: 5,
    name: "Marolins Dayanna Beleño Villarreal",
    cargo: "Asistente Administrativa",
    //category: "ADMNISTRATIVA Y FINANCIERA",
    phone: "3851050 Ext 101",
    email: "mbeleno@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/steve.jpg"
  },
  {
    key: 6,
    name: "Sandra Bueno Bedoya",
    cargo: "Coordinadora de Diseño y Publicidad",
    category: "DISEÑO",
    phone: "3851050 Ext. 110",
    email: "sbueno@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/steve.jpg"
  },
  {
    key: 7,
    name: "Nohemy Stevenson Molina",
    cargo: "Directora de Comunicaciones y Responsabilidad Social",
    category: "ESTUDIOS ECONOMICOS",
    phone: "3851050 Ext. 115",
    email: "nstevenson@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/steve.jpg"
  },
  {
    key: 7,
    name: "Claudia Vengoechea Lopez",
    cargo: "Directora Mercadeo y Ventas",
    category: "MERCADEO Y VENTAS",
    phone: "3851050 Ext. 102",
    email: "cvengoechea@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/steve.jpg"
  },
  {
    key: 8,
    name: "Angela Patricia Rodriguez Agudelo",
    cargo: "Coordinadora de mercadeo Y Ventas",
    //category: "MERCADEO Y VENTAS",
    phone: "3851050 Ext. 108",
    email: "aprodriguez@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/steve.jpg"
  },
  {
    key: 9,
    name: "Laura Restrepo Campo",
    cargo: "Directora Gestión Afiliados",
    category: "GESTION AFILIADOS",
    phone: "3851050 Ext. 105",
    email: "lrestrepo@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/steve.jpg"
  },
  {
    key: 10,
    name: "María Alejandra Morales Gonzalez",
    cargo: "Coordinadora Gestión Afiliado",
    //category: "GESTION AFILIADOS",
    phone: "3851050 Ext. 107",
    email: "mmorales@camacolatlantico.org",
    //picture: "https://react.semantic-ui.com/images/avatar/large/steve.jpg"
  }
]; */
const columns = [
	{
		dataIndex: "department",
		title: "Departamento",
	},
	{
		dataIndex: "job_position",
		title: "Cargo",
	},
	{
		dataIndex: "name",
		title: "Nombre",
	},
	{
		dataIndex: "email",
		title: "Correo Elecrónico",
	},
	{
		dataIndex: "phone",
		title: "Teléfono",
	},
];
const Contacto = (props) => {
	const [contacts, setContacts] = useState([]);

  // const pushUserDataLayer = () => {
	// 	//-----------------------------------------------
	// 	window.dataLayer = window.dataLayer || [];
	// 	getUserInfo().then((user) => {
	// 		const {company: { nit, name }, first_name } = user; 

	// 		const nuevoEvento = {
	// 			constructora: "Marval_Test", 
	// 			nombre_afiliado: "name",
	// 			nit_company: "0",
	// 			event: "customEvent",
	// 		};

	// 		nuevoEvento.constructora = name;
	// 		nuevoEvento.nombre_afiliado = first_name;
	// 		nuevoEvento.nit_company = nit; 
      
	// 		window.dataLayer[0] = nuevoEvento; 
	// 	});
	// 	//----------------------------------------------------------
	// }

	const getContacts = () => {
		const service = getService("contact-info");
		service
			.find({
        
				query: {
          $limit:50,
					$sort: {
            position: 1,
            
          }

        }
			})
			.then(({ data }) => {
				setContacts(data);
			})
			.catch((err) => message.error(err.message));
	};

	useEffect(() => {
		getContacts();
    // pushUserDataLayer(); 
	}, []);

	return (
		<Layout>
			<Row
				type="flex"
				justify="center"
				align="top"
				className="contact-container"
			>
				<Col className="contact-left" xs={24} xl={6} lg={24}>
					<Row className="subtitle-contact">
						<Col className="title-inline">
							<Icon type="phone" />
							<h2>Contáctanos</h2>
						</Col>
					</Row>
					<Row className="contact-info-item">
						<Col>
							<Icon type="phone" />
							<span>+57(5)3851050</span>
						</Col>
						<Col>
							<Icon type="mail" />
							<span>nstevenson@camacolatlantico.org</span>
						</Col>
					</Row>
					<Row className="contact-info-item">
						<Col>
							<div>
								<p>
									Centro Empresarial Buenavista <br />
									Carrera 53 # 106 - 280 Oficina 1 Torre A <br />
									Hotel Piso 18
								</p>
							</div>
							<div>
								<Icon type="environment" /> Barranquilla, Atlántico
							</div>
						</Col>
					</Row>
				</Col>
				<Col className="contact-right" xs={24} xl={18} lg={24}>
					<Table
						size="small"
						pagination={false}
						columns={columns}
						dataSource={contacts}
					/>
				</Col>
			</Row>
		</Layout>
	);
};
export default Contacto;
