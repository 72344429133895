import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Dropdown, Icon, Menu, message, Row } from "antd";
/* import { SearchBox, Select, Toggle, Group } from "../../components/algolia"; */
import { SelectField, SimpleForm } from "../../components/com/";
import { searchFilter } from "../../services";
import qs from "qs";
import styled from "styled-components";
import { URL_EXPORT_EXCEL } from "../../utils/Constants";

const WrapperForm = styled(SimpleForm)`
	&.ant-form-horizontal > .ant-row:nth-child(1) {
		width: 100% !important;
	}
	&.ant-form-horizontal > div {
		margin: 0px auto !important;
		display: flex !important;
	}
	&.ant-form-horizontal {
		padding: 5px 0px !important;
	}
	&.ant-form-horizontal .item-form {
		padding: 4px !important;
		max-width: 140px !important;
		flex: 1 !important;
	}
`;
const MenuWrapper = styled(Menu)`
	& .ant-dropdown-menu-item:hover,
	.ant-dropdown-menu-submenu-title:hover {
		background-color: #fff !important;
	}
`;
const WrapperButtonMenu = styled(Dropdown.Button)`
	background: #f4f7f8;
	border-radius: 8px !important;
	width: 100% !important;
	position: relative !important;
	display: block !important;
	& .ant-btn {
		background: #f4f7f8 !important;
		color: #ccc !important;
	}
	& .ant-btn:first-child {
		border-radius: 8px 0px 0px 8px !important;
		border-color: #f4f7f8 !important;
		width: 75% !important;
	}
	& .ant-btn:last-child:not(:first-child) {
		border-radius: 0px 8px 8px 0px !important;
		border-color: #f4f7f8 !important;
		width: 25% !important;
	}
	& span {
		color: #ccc !important;
	}
	& .anticon {
		font-size: 14px !important;
	}
`;
const Select = ({
	placeholder,
	name,
	filters = {},
	onHandleChange,
	...props
}) => {
	const [value, setValue] = useState(props.value);
	const handleOnChange = (field, value) => {
		setValue(value);
		if (onHandleChange) onHandleChange(field, value);
	};
	useEffect(() => {
		setValue(props.value);
	}, [props.value]);
	return (
		<SelectField
			style={{
				minWidth: 140,
				margin: "0px 8px",
			}}
			allowClear
			onChange={handleOnChange}
			name={name}
			dataSource={filters.years.map((key) => {
				return {
					name: key,
				};
			})}
			value={value}
			valueField="name"
			displayField="name"
			placeholder={placeholder}
		/>
	);
};
let years = {};
const MenuYears = ({ filters, handleChange, name, ...props }) => {
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({});
	const [value, setValue] = useState();
	const handleOnChange = (field, value) => {
		years[field] = value;
		if (!years["start_year"] && !years["end_year"]) {
			handleChange(name, null);
			/* setVisible(false); */
		}

		setState((state) => {
			let values = Object.values(years);
			setValue(values.join("-"));
			if (
				typeof years.start_year != "undefined" &&
				typeof years.end_year != "undefined"
			) {
				if (years.start_year <= years.end_year) {
					handleChange(name, values.join(","));
					setVisible(false);
				} else {
					message.error("El año inicial debe ser menor al final.");
				}
			}
			return {
				...state,
				[field]: value,
			};
		});
	};

	const menu = () => {
		return (
			<MenuWrapper>
				<Menu.Item>
					<Row type="flex" justify="space-between" align="middle" gutter={4}>
						<Col /* span={years["start_year"] && years["end_year"] ? 10 : "auto"} */
						>
							<Select
								name="start_year"
								filters={filters}
								value={state["star_year"]}
								placeholder="Año Inicial"
								/*  onClear={handleClear} */
								onHandleChange={handleOnChange}
							/>
						</Col>
						<Col /* span={years["start_year"] && years["end_year"] ? 10 : "auto"} */
						>
							<Select
								name="end_year"
								filters={filters}
								value={state["end_year"]}
								placeholder="Año Final"
								/*  onClear={handleClear} */
								onHandleChange={handleOnChange}
							/>
						</Col>
						{/* (years["start_year"] && years["end_year"]) && <Col span={4}>
            <Button style={{
              margin: "0px 8px"
            }} icon="close" type="dashed"
              onClick={() => {
                years = {};
                let values = Object.values(years);
                setValue(values.join("-"));
                setState({
                  end_year: undefined,
                  start_year: undefined
                });
                handleChange(name, null);
                setVisible(false);
              }}
            />
          </Col> */}
					</Row>
				</Menu.Item>
			</MenuWrapper>
		);
	};
	return (
		<WrapperButtonMenu
			size="large"
			placement="bottomCenter"
			visible={visible}
			onVisibleChange={(visible) => setVisible(visible)}
			trigger={["click"]}
			icon={<Icon type="down" />}
			overlay={menu}
		>
			<span>{!value || value == "-" ? "Años" : value}</span>
		</WrapperButtonMenu>
	);
};
const SearchForm = (props) => {
	const [defaultFilters, setDefaultFilters] = useState({
		projects: [],
		neighborhoods: [],
		stratus: [],
		zones: [],
		dates: [],
		years: [],
	});
	const [initialized, setInitialized] = useState(false);
	const [project, setProject] = useState();
	const [filters, setFilters] = useState(defaultFilters);
	const [payloads, setPayloads] = useState({});

	const handleChange = (name, value, field) => {
		if (!value) {
			setFilters({
				...filters,
				[field]: defaultFilters[field],
			});
		}
		setPayloads({ ...payloads, [name]: value || undefined });
		getFilters({ ...payloads, [name]: value || undefined });
	};
	const handleSearch = (value) => {};
	const handleSubmit = (err, data) => {
		if (props.onSubmit) props.onSubmit(err, data);
	};
	const getFilters = (payloads = {}) => {
		/* alert(JSON.stringify(payloads)); */
		return searchFilter(payloads)
			.then((data) => {
				data = data.length > 0 ? data[0] : defaultFilters;
				setFilters(data);
				if (props.onChange) props.onChange(payloads);
				if (!initialized) {
					setDefaultFilters(data);
					setInitialized(true);
				}
			})
			.catch((err) => message.error("ERROR: " + err.message));
	};
	useEffect(() => {
		let { location } = props;
		let { search } = location;
		let params = qs.parse(search.replace(/\?/i, ""));
		setProject(params.Proyecto);
		getFilters(payloads).then((data) => {
			//alert("ALERT:" + JSON.stringify(data));
		});
		return () => {};
	}, [props.location.search]);
	return (
		<WrapperForm
			layout="horizontal"
			autoSubmit={false}
			footer={
				false && (
					<Row>
						<Col span={24} align="center">
							<Button
								type="primary"
								htmlType="submit"
								className="login-form-button btn-green"
								style={{
									/*  width: "32%", */
									height: 40,
									padding: 5,
									margin: 0,
									textAlign: "center",
								}}
								size="large"
								block
							>
								Buscar
							</Button>
						</Col>
					</Row>
				)
			}
			/* textAcceptButton="Buscar" */
			onChange={handleChange}
			onSubmit={handleSubmit}
			style={{
				padding: "0px 20px",
			}}
		>
			<SelectField
				//flex={0.07}
				onChange={(name, value) => {
					if (value) handleChange(name, value, "cuenta");
				}}
				onSearch={handleSearch}
				name="cuenta"
				dataSource={[
					{
						name: "Ventas",
					},
					{
						name: "Oferta",
					},
				]}
				initialValue={"Ventas"}
				valueField="name"
				displayField="name"
				//label="Cuenta"
				placeholder="Cuenta"
			/>

			<SelectField
				//flex={0.07}
				onChange={(name, value) => handleChange(name, value, "projects")}
				onSearch={handleSearch}
				name="Proyecto"
				initialValue={project}
				dataSource={filters.projects.map((key) => {
					return {
						name: key,
					};
				})}
				valueField="name"
				displayField="name"
				//label="Proyecto"
				placeholder="Proyecto"
			/>
			<MenuYears
				name="Año"
				filters={filters}
				handleChange={(name, value) => {
					handleChange(name, value, "years");
				}}
			/>
			{/* <SelectField
        onChange={(name, value) => {
          console.log("---->", name, value);
          handleChange(name, value, "years");
        }}
        name="Año"
        dataSource={filters.years.map(key => {
          return {
            name: key
          };
        })}
        valueField="name"
        displayField="name"
        //flex={0.07}
        //label="Año"
        placeholder="Año"
      /> */}
			<SelectField
				onChange={(name, value) => handleChange(name, value, "dates")}
				name="Fecha"
				/* mode="multiple" */
				dataSource={filters.dates.map((key) => {
					return {
						name: key,
					};
				})}
				valueField="name"
				displayField="name"
				//flex={0.07}
				//label="Fecha"
				placeholder="Fecha"
			/>
			<SelectField
				/* mode="multiple" */
				onChange={(name, value) => handleChange(name, value, "neighborhoods")}
				name="Barrio"
				dataSource={filters.neighborhoods.map((key) => {
					return {
						name: key,
					};
				})}
				valueField="name"
				displayField="name"
				//flex={0.07}
				//label="Barrio"
				placeholder="Barrio"
			/>
			<SelectField
				/* mode="multiple" */
				onChange={(name, value) => handleChange(name, value, "zones")}
				name="Zona"
				dataSource={filters.zones.map((key) => {
					return {
						name: key,
					};
				})}
				valueField="name"
				displayField="name"
				//flex={0.07}
				//label="Zona"
				placeholder="Zona"
			/>
			<SelectField
				/* mode="multiple" */
				onChange={(name, value) => {
					handleChange(name, value, "stratus");
				}}
				name="Estrato"
				dataSource={filters.stratus.map((key) => {
					return {
						name: key,
					};
				})}
				valueField="name"
				displayField="name"
				//flex={0.07}
				//label="Estrato"
				placeholder="Estrato"
			/>
			{props.show && (
				<Button size="large" className="btn-green">
					<a href={URL_EXPORT_EXCEL} target="_blank">
						<Icon size="large" type="file-excel" /> Descargar
					</a>
				</Button>
			)}
		</WrapperForm>
	);
};
export default withRouter(SearchForm);
