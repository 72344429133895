import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

const data = [
	{ name: "Group A", value: 400 },
	{ name: "Group B", value: 300 },
	{ name: "Group C", value: 300 },
	{ name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	index,
}) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			x={x}
			y={y}
			fill="white"
			textAnchor={x > cx ? "start" : "end"}
			dominantBaseline="central"
		>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const CustomPieChart = ({ size = 400, ...props }) => {
	let data = props.data || [];
	return (
		<PieChart width={size + 60} height={size}>
			<Pie
				/* isAnimationActive={false} */
				paddingAngle={0}
				/* outerRadius={80}
        innerRadius={40} */
				labelLine={false}
				data={data}
				cx={225}
				cy={200}
				fill="#8884d8"
				dataKey="value"
				label={renderCustomizedLabel}
			>
				{data.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
				))}
			</Pie>
			<Tooltip />
		</PieChart>
	);
};
export default CustomPieChart;
/* <PieChart width={200} height={200}>
      <Pie
        data={props.data || data}
        cx={100}
        cy={100}
        labelLine={false}
        label={renderCustomizedLabel}
        innerRadius={60}
        paddingAngle={5}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart> */
