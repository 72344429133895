import React, { Component } from "react";
import { Button, Dropdown, Menu } from "antd";
import "./dropdown.css";

class DropDownMenu extends Component {
	static count = 0;
	static current = null;
	static opened = false;

	constructor(props) {
		super(props);
		DropDownMenu.count++;

		this.name = props.name || "drop_down_" + DropDownMenu.count;
	}
	state = {
		visible: false,
	};
	handleChange = (values) => {
		let { selection } = this.props;
		if (values.length > 0) selection = values;
		if (typeof this.props.onChange !== "undefined") this.props.onChange(values);
	};

	render() {
		let props = this.props;
		let { label, onChange, minChecked } = props;
		let defaultValue = props.defaultValue || [];
		var menu = props.menu || (
			<Menu
				className="property-type-menu"
				style={
					props.menuStyle || {
						width: 200,
					}
				}
			>
				<Menu.Item>{props.children}</Menu.Item>
			</Menu>
		);
		let selection =
			typeof props.selection !== "undefined"
				? props.selection.length > 0
					? props.selection
					: defaultValue
				: defaultValue;

		let visible = this.state.visible || false;

		let { name } = this;
		DropDownMenu.opened = DropDownMenu.current === name;

		let data = props.data || [];

		let style = {
			minWidth: "320px",
			maxWidth: "400px",
		};

		if (props.size === "small") {
			style = {
				minWidth: "200px",
				maxWidth: "400px",
			};
		}
		return (
			<Dropdown
				className="drop-down-menu"
				/*  style={{ ...props.style }} */
				overlay={menu}
				overlayStyle={{ width: "auto" }}
				visible={DropDownMenu.opened}
				trigger={["click"]}
				onVisibleChange={(e) => {
					visible = !visible;
					if (typeof props.onClick !== "undefined") {
						props.onClick();
					}
				}}
				onClick={() => {
					let { name } = this;
					if (DropDownMenu.current !== name) {
						DropDownMenu.current = name;
					} else {
						DropDownMenu.current = null;
					}
					this.setState({
						visible: !this.state.visible,
					});
					if (typeof props.onClick !== "undefined") {
						props.onClick();
					}
				}}
			>
				<Button
					size={props.size || "large"}
					className={props.className || "btn-icon-right"}
					style={props.style || { margin: 0, width: "100%" }}
					block
					icon={props.icon || "down"}
				>
					{props.prefix && (
						<span style={{ float: "left", verticalAlign: "middle" }}>
							{props.prefix}
						</span>
					)}
					{label}{" "}
				</Button>
			</Dropdown>
		);
	}
}

export default DropDownMenu;
