import React, { Component } from "react";
import { connectToggleRefinement } from "react-instantsearch-dom";
import { Radio } from "antd";

export default class Toggle extends Component {
	state = {
		value: false,
	};
	render() {
		const ToggleRefinement = ({ refine, currentRefinement }) => {
			return (
				<Radio.Group
					value={currentRefinement}
					label={"-"}
					onChange={(e) => {
						let { value } = e.target;
						refine(value);
					}}
				>
					{this.props.children}
				</Radio.Group>
			);
		};
		const CustomToggleRefinement = connectToggleRefinement(ToggleRefinement);
		let { value } = this.state;
		return (
			<CustomToggleRefinement
				label={"a"}
				value={value}
				attribute={this.props.attribute}
			/>
		);
	}
}
