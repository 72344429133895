import React from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import { API_KEY, APPLICATION_ID, INDEX } from "../../utils/Constants";

const searchClient = algoliasearch(APPLICATION_ID, API_KEY);
const Instant = (props) => (
	<InstantSearch
		searchableAttributes={["type_affiliate.name"]}
		indexName={props.index || INDEX}
		searchClient={searchClient}
		{...props}
	>
		{props.children}
	</InstantSearch>
);
export default Instant;
