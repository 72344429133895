import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Divider, Icon, Row } from "antd";
import { S3_INSTANCE_CONSTRUCARIBE } from "../../utils/Constants";
import { decimal, money } from "../../utils/Helper";

const styles = {
	flex: {
		paddingLeft: "15px",
	},
	box: {
		margin: "5px 0px 8px 0px",
		fontSize: "12px",
	},
};
const ProjectDetail = (props) => {
	let {
		image,
		name = "",
		min_last_price_in_thousands,
		min_area_by_type,
		address = "",
		stratus,
	} = props;

	const handleClick = (props) => {
		let { history } = props;
		history.replace(
			"/asesoria-comercial/?Proyecto=" + props.name.toUpperCase()
		);
	};
	return (
		<div className="project-detail">
			<Row type="flex" justify="space-around" align="top" gutter={16}>
				<Col span={8} align="center">
					<div>
						<div
							style={{
								margin: "20px auto",
							}}
						>
							<div
								className="cover-project"
								style={{
									width: "100%",
									height: 200,
									background: `url(${S3_INSTANCE_CONSTRUCARIBE}${image})`,
								}}
							/>
						</div>
					</div>
				</Col>
				<Col span={16}>
					<Row
						style={{
							marginTop: 20,
						}}
						type="flex"
						justify="space-around"
						align="middle"
						gutter={16}
					>
						<Col span={24} style={styles.flex}>
							<h2>{name.toUpperCase()}</h2>
						</Col>
						<Col span={24} style={styles.flex}>
							<Row type="flex" justify="start" align="middle" gutter={8}>
								<Col>
									<span>Desde:</span>
									<span className="value-field">
										{money(min_last_price_in_thousands)}
									</span>
								</Col>
								<Divider type="vertical" />
								<Col>
									<span>Áreas desde: </span>
									<span className="value-field">
										{decimal(min_area_by_type, {
											precision: 0,
											symbol: "m2",
										})}
									</span>
								</Col>
								<Divider type="vertical" />
								<Col>
									<span>{stratus}</span>
								</Col>
							</Row>
						</Col>
						<Col span={24} style={styles.flex}>
							{address && (
								<div style={styles.box}>
									<Icon theme="twoTone" type="environment" />{" "}
									{address.toUpperCase()}
								</div>
							)}
						</Col>
					</Row>
					<Row>
						<div>
							<Button
								onClick={() => handleClick(props)}
								block
								className="btn-green"
							>
								ANALIZAR ACTIVIDAD EDIFICADORA
							</Button>
						</div>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default withRouter(ProjectDetail);
