import React, { Component } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { Icon, Input } from "antd";

export default class CustomSearchBox extends Component {
	render() {
		const SearchBox = ({
			name,
			placeholder,
			currentRefinement,
			refine,
			className,
			size,
			iconAlign = "left",
		}) => (
			<Input
				className={className}
				size={size}
				prefix={iconAlign === "left" && <Icon type="search" />}
				suffix={iconAlign === "right" && <Icon type="search" />}
				placeholder={placeholder}
				type="search"
				name={name}
				value={this.props.initial || currentRefinement}
				onChange={(event) => {
					refine(event.currentTarget.value);
				}}
			/>
		);
		const CustomSearchBox = connectSearchBox(SearchBox);
		return <CustomSearchBox {...this.props} />;
	}
}
