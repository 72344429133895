import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import Smartlook from 'smartlook-client'

// Smartlook.init('6ae277bfd33520344c3c4450b94e2fc283ba0f98', { region: 'eu' }); 

import ReactGA from 'react-ga4';

// Inicializa Google Analytics al inicio de la aplicación
ReactGA.initialize('GTM-M4RRW7B'); 

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
