import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "../../components/com/form/AdvancedForm";
import { Button, Col, Divider, Icon, Input, Row } from "antd";
import { SelectField } from "../../components/com";
import { allowAccess } from "../../utils/Helper";

const styles = {
	flex: {
		width: "100%",
	},
};
const LandForm = (props) => {
	let { onChange } = props;
	const handleSubmit = (err, data) => {};
	const handleClik = () => {
		let { history } = props;
		history.replace("/gestionar-lotes");
	};
	const [isInmobiliaria, setIsInmobiliaria] = useState(false);
	useEffect(() => {
		setIsInmobiliaria(allowAccess());
		return () => {};
	}, []);
	return (
		<Form
			{...props}
			onSubmit={handleSubmit}
			autoSubmit={false}
			textAcceptButton="BUSCAR"
			buttonSubmitStyle={{
				width: "100%",
			}}
			footer={
				<div>
					<Divider />
					<Button onClick={handleClik} size="large" block className="btn-green">
						ADMINISTRAR LOTES
					</Button>
				</div>
			}
		>
			<div>
				<Row type="flex" justify="center" style={styles.flex}>
					<Col className="form-search-title" span={24}>
						<h2>
							<Icon type="search" /> BUSCADOR DE LOTES
						</h2>
					</Col>
				</Row>
				<Input
					label="Matrícula inmobiliaría"
					name="real_estate_registration"
					style={styles.flex}
					size="large"
					suffix={<Icon type="search" />}
					placeholder="Buscar"
					type="search"
					onChange={onChange}
				/>
				<SelectField
					label="Ciudad"
					name="city.name"
					valueField="name"
					dataSource={props.cities || []}
					attribute={"city.name"}
					style={styles.flex}
					placeholder="Ciudad"
					onChange={onChange}
				/>
			</div>
		</Form>
	);
};

export default withRouter(LandForm);
