import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
/* Redux */
import rootReducer from "../reducers";
/* Redux Store */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const MainStore = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk))
);

export { MainStore };
