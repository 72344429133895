import React from "react";
import { Card, Icon } from "antd";
import { Link } from "react-router-dom";

const { Meta } = Card;
const CardItem = (props) => (
	<Card
		className="card-item"
		hoverable
		style={{ width: 240 }}
		cover={
			<div className="card-cover-container">
				<Link to={props.pathname || ""}>
					<div
						className="card-cover"
						alt="example"
						style={{
							backgroundImage: `url(${
								props.image ||
								"https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
							})`,
						}}
					></div>
				</Link>
			</div>
		}
	>
		<Meta
			title={
				<div className="card-title">
					{props.icon && <Icon type={props.icon} />}
					<h2>{props.title}</h2>
				</div>
			}
			description={props.description}
		/>
	</Card>
);
export default CardItem;
