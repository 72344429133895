import React, { useEffect } from "react";
import { Col, Layout, Row } from "antd";
import "./contacto_layout.css";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { menuService } from "../../services/services";
import { setMenuHeader } from "../../redux/actions";

const Cover = require("../../resources/images/cover-contact.png");

const ContactoLayout = ({ children }) => {
	const menus = useSelector(state => state.ui.menus) || []
	const dispatch = useDispatch()

	const getMenus = async () => {
		await menuService.find({
			query: { status: 'active', $sort: { position: 1 } }
		}).then(({data}) => {
			dispatch(setMenuHeader(data))
		})
	}

	useEffect(() => {
		if (!menus.length) getMenus()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menus])

	return (
		<Layout className="contact-layout">
			<Header />
			<Row justify="center" type="flex" align="middle">
				<div
					className="contact-cover"
					style={{
						backgroundImage: `url(${Cover})`,
					}}
				></div>
			</Row>
			<Row
				type="flex"
				justify="center"
				gutter={{
					xl: 16,
				}}
				className="contact-container bg-main-container"
			>
				<Col span={18} className="contact-content">
					<div>{children}</div>
				</Col>
			</Row>
			{/* <Footer /> */}
		</Layout>
	)
};
export default ContactoLayout;
