import React from "react";
import { Layout, Row } from "antd";

const { Footer } = Layout;
const FooterLight = (props) => {
	return (
		<Footer className={props.className || "footer-ligth"}>
			<Row type="flex" justify="center">
				<span>
					Copyright Camacol -{" "}
					<a
						style={{ color: "inherit" }}
						href="https://e-me.co"
						target="_blank"
					>
						{" "}
						e-me.co
					</a>
				</span>
			</Row>
		</Footer>
	);
};
export default FooterLight;
