import React, { useEffect, useState } from "react";
import Layout from "../../layouts/main_layout/mainLayout";
import { CRUD } from "../../components/com";
import Modal from "../../components/com/modal/Modal";
import { Avatar, Button, Col, message, Row, Table } from "antd";
import { buy_camacol } from "../../services/services";
import { getBuyCategories, getService } from "../../services";
import { S3_INSTANCE_CAMACOL } from "../../utils/Constants";
/* import "./crud_land.css"; */
const columns = [
	{
		dataIndex: "buy_camacol.image_path",
		render: (value, record) => (
			<Avatar
				size="large"
				src={`${S3_INSTANCE_CAMACOL}${value}`}
				shape="circle"
			/>
		),
	},
	{
		dataIndex: "buy_camacol.contact_full_name",
		title: "Nombre",
		render: (value, record) => `${value || ""} ${record.last_name || ""}`,
	},
	{
		dataIndex: "buy_camacol.contact_email",
		title: "Email",
	},
	{
		dataIndex: "buy_camacol.contact_phone",
		title: "Teléfono",
	},
];
const Ofertas = (props) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	let [company_id, setCompanyID] = useState(
		localStorage.getItem("user")
			? JSON.parse(localStorage.getItem("user")).company_id
			: undefined
	);
	const handleInterested = (id) => {
		setVisible(true);
		getInterestes(id);
	};
	const getInterestes = (id) => {
		const service = getService("users-interested-buy-camacol");
		setLoading(true);
		service
			.find({
				query: {
					buy_camacol_id: id,
				},
			})
			.then(({ data }) => {
				setData(data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};
	useEffect(() => {
		let { history } = props;
		/* if (!allowAccess()) return history.replace("/");*/

		return () => { };
	}, []);

	return (
		<Layout /* className="layout-crud-land" */>
			<Row type="flex" justify="center" align="top">


				<Col span={18}>
					<CRUD
						allowCreate={true}
						is_crud={true}
						title="Mis Publicaciones"
						service={buy_camacol}
						params={{
							query: {
								company_id,
							},
						}}
						fields={[
							{
								name: "id",
								type: "hidden",
							},
							{
								xtype: "fileuploader",
								name: "image_path",
								label: "Imagen opcional del producto o servicio",
								labelLogo: "Las medias recomendadas son de 150 x 150 px",
								flex: 1,
							},
							{
								name: "name",
								validations: [
									{
										required: true,
										message: " es requerido",
									},
								],
								label: "Título de Publicación",
								placeholder: "Título de Publicación",
							},
							{
								name: "type",
								validations: [
									{
										required: true,
										message: " es requerido",
									},
								],
								xtype: "selectfield",
								model: {
									data: [
										{
											name: "Servicios",
										},
										{
											name: "Insumos",
										},
									],
								},
								valueField: "name",
								label: "Insumos ó Servicios",
								placeholder: "Insumos ó Servicios",
								validations: [
									{
										required: true,
										message: " es requerido",
									},
								],
							},
							{
								name: "buy_camacol_category_id",
								xtype: "selectfield",
								model: {
									service: getBuyCategories,
								},
								label: "Categoría",
								placeholder: "Categoría",
								validations: [
									{
										required: true,
										message: " es requerido",
									},
								],
							},
							{
								name: "contact_full_name",
								label: "Persona de contácto",
								placeholder: "Persona de contácto",
								validations: [
									{
										required: true,
										message: " es requerido",
									},
								],
							},
							{
								name: "contact_email",
								type: "email",
								label: "Email de contácto",
								placeholder: "Email de contácto",
								validations: [
									{
										required: true,
										message: " es requerido",
									},
								],
							},
							{
								name: "contact_phone",
								label: "Celular de contácto",
								placeholder: "Celular de contácto",
								validations: [
									{
										required: true,
										message: " es requerido",
									},
								],
							},
							{
								xtype: "textarea",
								name: "description",
								label: "Descripción",
								flex: 1,
								placeholder: "Descripción",
								validations: [
									{
										required: true,
										message: " es requerido",
									},
								],
							},
						]}
						columns={[
							{
								dataIndex: "name",
								text: "Nombre",
							},
							{
								dataIndex: "type",
								text: "Tipo",
							},
							{
								dataIndex: "buy_camacol_category.name",
								text: "Categoria",
								render: (record) => {
									let { buy_camacol_category } = record;
									return <span>{buy_camacol_category.name}</span>;
								},
							},
							{
								render: (record) => (
									<>
										<Button
											type="link"
											icon="usergroup-add"
											shape="circle"
											onClick={() => handleInterested(record.id)}
										>
											VER INTERESADOS
										</Button>
									</>
								),
							},
						]}
					/>
				</Col>
			</Row>
			<Modal
				closabled
				width={600}
				title="Interesados en esta publicación"
				footer={null}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<Table
					pagination={false}
					loading={loading}
					size="small"
					columns={columns}
					dataSource={data}
				/>
			</Modal>
		</Layout>
	);
};

export default Ofertas;
