import React, { Component } from "react";
/* Router */
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
/* Styles */
import "./App.css";
import "./App.scss";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
/* Overwrite */
import "./overwrite/index";
import { getUserInfo, reautentication } from "./services";
import { Preloader } from "./components/com";
import { authenticate } from "./redux/actions";
/* Import Store */
import { MainStore } from "./redux/stores";
import {
	Afiliados,
	AsesoriaComercial,
	Compra,
	Contacto,
	Home,
	Land,
	Lands,
	Login,
	Ofertas,
	Perfil,
	Tramites_construccion,
	VisualizacionPdf,
	Statistics,
	Dashboard
} from "./pages";
import { Statistic } from "antd";
//import AsesoriaComercial from "./pages/asesoria_comercial/_AsesoriaComercial";

MainStore.subscribe(function () {
});
const NotFound = () => <div>Not Found</div>;
const NotAllow = () => <div>Not Allowed</div>;

class App extends Component {
	state = {
		loggedIn: false,
		loading: true,
	};

	componentWillMount() {

		let loggedIn = localStorage.getItem("feathers-jwt") != null;
		if (!loggedIn) {
			this.setState({
				loggedIn,
				loading: false,
			});
			//MainStore.dispatch(authenticate({ loggedIn }));
		}
		reautentication()
			.then((response) => {

				getUserInfo().then((user) => {
					MainStore.dispatch(authenticate({ loggedIn: true, user }));
				});

				this.setState({
					loggedIn: true,
					loading: false,
				});
			})
			.catch((err) => {
				MainStore.dispatch(authenticate({ loggedIn: false }));
				this.setState({
					loggedIn: false,
					loading: false,
				});
				/*  message.error(err.message);*/
			});
	}
	render() {
		let { loggedIn, loading } = this.state;
		return (
			<Provider store={MainStore}>
				<>
					{loading ? (
						<Preloader />
					) : (
						<Router>
							<Switch>
								<Route exact path="/">
									{loggedIn ? <Redirect to="/home" /> : <Login />}
								</Route>
								<Route exact path="/home" component={Home} />
								<Route exact path="/lotes" component={Lands} />
								<Route exact path="/contacto" component={Contacto} />
								<Route exact path="/compra" component={Compra} />
								<Route exact path="/afiliados" component={Afiliados} />
								<Route exact path="/perfiles" component={Perfil} />
								<Route exact path="/estadisticas" component={Dashboard} />
								<Route
									exact
									path="/asesoria-comercial"
									component={AsesoriaComercial}
								/>
								<Route exact path="/tramites_construccion" component={Tramites_construccion} />
								<Route exact path="/tramites_construccion/:id" component={VisualizacionPdf} />
								<Route exact path="/gestionar-lotes" component={Land} />
								<Route exact path="/gestionar-ofertas" component={Ofertas} />
								<Route path="/not-allow" component={NotAllow} />
								<Route component={NotFound} />
							</Switch>
						</Router>
					)}
				</>
			</Provider>
		);
	}
}

export default App;
