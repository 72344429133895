import React, { Component } from "react";
import { Spin } from "antd";
import "./preloader.css";

class Preloader extends Component {
	render() {
		return (
			<div className="page-preloading">
				<div className="preloading" />
				<div className="preloading-container">
					<Spin size="large" tip={<a href="/">Cargando...</a>} />
				</div>
			</div>
		);
	}
}

export default Preloader;
