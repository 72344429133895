import React, { Component } from "react";
/* Redux Connect */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authenticate, isLoggedIn } from "../../redux/actions";

/* Import Custom Components */
import Layout from "../../layouts/home_layout/homelayout";
import CardList from "../../components/card/CardList";
import { Col, Row } from "antd";
import { getUserInfo } from "../../services";
const image01 = require("../../resources/images/image01.png");
const image02 = require("../../resources/images/image02.png");
const image03 = require("../../resources/images/image03.png");
const image04 = require("../../resources/images/image04.png");
const image05 = require("../../resources/images/image05.jpeg");
// const image06 = require("../../resources/images/img06.jpg");
/* Card */
const cards = [
	{
		key: "BUSCADOR DE LOTES",
		title: "BUSCADOR DE LOTES",
		icon: "search",
		image: image01,
		pathname: "/lotes",
		description: "",
	},
	{
		key: "ACTIVIDAD EDIFICADORA",
		title: "ACTIVIDAD EDIFICADORA",
		icon: "fund",
		image: image04,
		pathname: "/asesoria-comercial",
		description: "",
	},
	{
		key: "CAMACOL COMPRA CAMACOL",
		title: "CAMACOL COMPRA CAMACOL",
		icon: "dollar",
		image: image03,
		pathname: "/compra",
		description: "",
	},
	{
		key: "DIRECTORIO AFILIADOS",
		title: "DIRECTORIO AFILIADOS",
		icon: "user",
		image: image02,
		pathname: "/afiliados",
		description: "",
	},
	{
		key: "TRAMITES_CONSTRUCCION",
		title: "TRAMITES PARA LA CONSTRUCCION",
		icon: "file-pdf",
		image: image05,
		pathname: "/tramites_construccion",
		description: "",
	},
	// {
	// 	key: "ESTADISTICAS_CAMACOL",
	// 	title: "ESTADISTICAS",
	// 	icon: "fund",
	// 	image: image06,
	// 	pathname: "/estadisticas",
	// 	description: "",
	// }
];

class Home extends Component {
	componentWillReceiveProps(nextProps) {
		const { data = {}, history } = nextProps;
		if ("loggedIn" in data) if (!data["loggedIn"]) return history.replace("/");
	}

	componentDidMount() {
		//-----------------------------------------------
		window.dataLayer = window.dataLayer || [];
		getUserInfo().then((user) => {
			const {company: { nit, name }, first_name } = user; 

			const nuevoEvento = {
				constructora: "Marval_Test", 
				nombre_afiliado: "name",
				nit_company: "0",
				event: "login",
			};

			nuevoEvento.constructora = name;
			nuevoEvento.nombre_afiliado = first_name;
			nuevoEvento.nit_company = nit; 

			// console.log({user}); 

			window.dataLayer.push(nuevoEvento)
		});
		//-----------------------------------------------------------
	}

	render() {
		return (
			<Layout>
				<div>
					<Row>
						<Col span={32}>
							<h1 className="home-title">
								Herramientas <span className="text-primary">Camacol</span>
							</h1>
						</Col>
					</Row>
					<Row>
						<Col span={32}>
							<CardList dataSource={cards} />
						</Col>
					</Row>
				</div>
			</Layout>
		);
	}
}
/* Mapping Actions to Props*/
const mapStateToProps = (state) => {
	const { data } = state;
	return {
		data,
	};
};
/* Event Action Dispatch to Props */
const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators({ authenticate, isLoggedIn }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
