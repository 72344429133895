import React, { Component, useState } from "react";
import Layout from "../../layouts/main_layout/mainLayout";
import {
	Avatar,
	Button,
	Card,
	Col,
	Icon,
	List,
	message,
	PageHeader,
	Row,
} from "antd";
import CompraForm from "../../components/company/CompraForm";
import "./compra.css";
import { Hits, Instant } from "../../components/algolia";
import { Modal } from "../../components/com";
import { getService, getUserInfo } from "../../services/";   
import { NO_IMAGE, S3_INSTANCE_CAMACOL } from "../../utils/Constants";
import ReactGA from 'react-ga4';


const company_image = require("../../resources/images/company.png");
const companies = [
	{
		title: "Ventana lorem ipsum",
		picture: company_image,
		logo:
			"https://pbs.twimg.com/profile_images/1002192885335449603/U727qKH0.jpg",
		description:
			"Amet laborum adipisicing labore dolor est do veniam anim labore laboris cupidatat.",
		name: "Empresa S.A.S",
	},
	{
		title: "Ventana lorem ipsum",
		picture: company_image,
		logo:
			"https://pbs.twimg.com/profile_images/1002192885335449603/U727qKH0.jpg",
		description: "Est mollit eu eiusmod est Lorem.",
		name: "Empresa S.A.S",
	},
	{
		title: "Ventana lorem ipsum",
		picture: company_image,
		logo:
			"https://pbs.twimg.com/profile_images/1002192885335449603/U727qKH0.jpg",
		description:
			"Do eu proident qui aliqua tempor sint dolore occaecat esse nulla laborum fugiat cupidatat pariatur. Do eu proident qui aliqua tempor sint dolore occaecat esse nulla laborum fugiat cupidatat pariatur.Do eu proident qui aliqua tempor sint dolore occaecat esse nulla laborum fugiat cupidatat pariatur.",
		name: "Empresa S.A.S",
	},
	{
		title: "Ventana lorem ipsum",
		picture: company_image,
		logo:
			"https://pbs.twimg.com/profile_images/1002192885335449603/U727qKH0.jpg",
		description: "Consequat culpa cillum non Lorem aliqua ad.",
		name: "Empresa S.A.S",
	},
];

const { Meta } = Card;
const CompanyItem = (props) => {
	const [visible, setVisible] = useState(false);
	const [interesting, setInteresting] = useState(false);
	const handleInterested = (id) => {
		const service = getService("users-interested-buy-camacol");

		console.log({props}); 
		
		setInteresting(true);
		service
			.create({
				buy_camacol_id: id,
			})
			.then((response) => {
				setInteresting(false);
				setVisible((visible) => !visible);
			})
			.catch((error) => {
				setInteresting(false);
				setVisible((visible) => !visible);
				message.error(error.message);
			});

			getUserInfo().then((user) => {
				const {company: { nit, name }, first_name } = user; 
	
				const nuevoEvento = {
					constructora: "", 
					nombre_afiliado: "",
					nit_company: "",
					event: "select_item",
					provider_name:"",
					provider_contact_email:"",
					provider_contact_name:"",
					provider_product_type:"",
					provider_product_category:"",
					provider_product_name:""
				};
	
				nuevoEvento.constructora = name;
				nuevoEvento.nombre_afiliado = first_name;
				nuevoEvento.nit_company = nit; 
				nuevoEvento.provider_name = props.company.name;
				nuevoEvento.provider_contact_email = props.contact_email;
				nuevoEvento.provider_contact_name = props.contact_full_name;
				nuevoEvento.provider_product_type = props.type;
				nuevoEvento.provider_product_category = props.buy_camacol_category.name;
				nuevoEvento.provider_product_name = props.name

				console.log(nuevoEvento); 
		        
				window.dataLayer.push(nuevoEvento); 
			});
	};

	return (
		<div className="card-item-company-container">
			<Card className="card-item" bordered={false}>
				<Meta
					title={
						<div className="card-title-company-container">
							<Row>
								<Col span={24}>
									<Row type="flex" gutter={8}>
										<Col>
											<Avatar
												shape={!props.company ? "square" : "circle"}
												src={
													props.company
														? `${S3_INSTANCE_CAMACOL}${props.company.logo}`
														: NO_IMAGE
												}
											/>{" "}
										</Col>
										<Col>
											<h2>{props.company ? props.company.name : ""}</h2>
										</Col>
									</Row>
								</Col>
								<Col
									xs={24}
									md={24}
									lg={4}
									xl={4}
									className="picture-company-container"
								>
									<div className="cover-container-company">
										<div
											className="company-picture"
											style={{
												backgroundImage: `url(
                        
                        ${
													props.image_path
														? S3_INSTANCE_CAMACOL + "" + props.image_path
														: NO_IMAGE
												}
                        
                        
                        )`,
												width: 60,
											}}
										/>
									</div>
								</Col>
								<Col xs={24} md={24} lg={20} xl={20}>
									<div className="company-info-container">
										<Row>
											<Row>
												<h2 className="company-title">{props.name}</h2>
											</Row>
											<Row>
												<Row
													className="company-options"
													type="flex"
													gutter={16}
												>
													<Col>
														<Icon type="experiment" /> {props.type}
													</Col>
													<Col>
														<Icon type="windows" />{" "}
														{props.buy_camacol_category.name}
													</Col>
												</Row>
												<Row>
													<p className="company-description">
														{props.description}
													</p>
												</Row>
												<Row
													className="company-name"
													type="flex"
													justify="space-between"
												>
													<Col className="company-actions-container">
														{visible && (
															<Row>
																<Col>
																	<h2>INFORMACIÓN DE CONTÁCTO</h2>
																	{props.contact_full_name && (
																		<div>
																			<Icon type="user" />{" "}
																			<span>{props.contact_full_name}</span>
																		</div>
																	)}
																	{props.contact_phone && (
																		<div>
																			<Icon type="phone" />{" "}
																			<span>{props.contact_phone}</span>
																		</div>
																	)}
																	{props.contact_email && (
																		<div>
																			<Icon type="mail" />{" "}
																			<span>{props.contact_email}</span>
																		</div>
																	)}
																</Col>
															</Row>
														)}
														{!visible && (
															<Button
																loading={interesting}
																onClick={() => handleInterested(props.id)}
																className="btn-green"
															>
															    {/* -------------*************--------------------BOTON-------------------------***********--------------------------- */}
																ME INTERESA

															</Button>
														)}
													</Col>
												</Row>
											</Row>
										</Row>
									</div>
								</Col>
							</Row>
						</div>
					}
				/>
			</Card>
		</div>
	);
};
class Compra extends Component {
	state = {
		visible: false,
		record: {},
	};
	handleContactar = (record) => {
		this.setState({
			visible: true,
			record,
		});
	};
	handleClick = () => {
		let { history } = this.props;
		history.replace("/gestionar-ofertas");
	};
	render() {
		let { visible } = this.state;
		return (
			<Layout className="compra-layout">
				<Instant index="buy_camacol">
					<Row
						gutter={16}
						type="flex"
						justify="center"
						align="middle"
						className="compra-container"
					>
						<Row
							style={{
								margin: 20,
								width: "calc(100% - 40px)",
							}}
						>
							<CompraForm />
						</Row>
						<Row>
							<PageHeader
								title={
									<Row
										gutter={8}
										type="flex"
										justify="space-between"
										align="middle"
									>
										<Col>
											<h2 className="title-compra">
												<Icon type="dollar" /> Camacol compra{" "}
												<span className="text-primary">Camacol</span>
											</h2>
										</Col>
										<Col>
											<Button className="btn-green" onClick={this.handleClick}>
												ADMINISTRAR MIS PUBLICACIONES
											</Button>
										</Col>
									</Row>
								}
							/>
							<Hits
								grid={{
									size: "small",
									gutter: 16,
									column: 1,
									/* xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                  xxl: 1 */
								}}
								renderItem={(record) => (
									<List.Item>
										<CompanyItem
											{...record}
											onClick={() => this.handleContactar(record)}
										/>
									</List.Item>
								)}
								dataSource={companies}
							/>
						</Row>
					</Row>

					<Modal
						width={600}
						className="modal-detail"
						footer={null}
						onCancel={() => {
							this.setState({
								visible: false,
								record: {},
							});
						}}
						title={null}
						visible={visible}
					>
						<div
							style={{
								height: 400,
								margin: 10,
							}}
						>
							<Row type="flex" justify="start" align="middle">
								<Col span={6}>Imagen</Col>
								<Col span={18}>Información</Col>
							</Row>
						</div>
					</Modal>
				</Instant>
			</Layout>
		);
	}
}

export default Compra;
