import React, { Component } from "react";
import styled from "styled-components";
import { Configure, connectHits, Pagination } from "react-instantsearch-dom";
import CardList from "../card/CardList";
import { Col, Row } from "antd";

const Paginator = styled(Pagination)`
	& ul {
		display: flex !important;
	}
	& li {
		margin: 0px 4px !important;
		list-style: none !important;
	}
	& li a,
	span.ais-Pagination-link {
		background: #f8f8f8;
		padding: 5px 10px;
		/* border: 1px solid #ccc; */
		border-radius: 4px;
		box-shadow: 2px 2px 2px #ccc;
		font-size: 16px;
		font-weight: bold;
	}
	span.ais-Pagination-link {
		box-shadow: none !important;
	}
`;
const DefaultHit = ({ hit, insights }) => <Col>{hit.name}</Col>;
class Hits extends Component {
	render() {
		const props = this.props;
		let { hitsPerPage } = props;
		let Hits = ({ hits }) => {
			return (
				<div
					style={{
						margin: 10,
					}}
				>
					<CardList
						grid={props.grid}
						renderItem={props.renderItem}
						dataSource={hits}
					/>
				</div>
			);
		};
		const CustomHits = connectHits(Hits);
		return (
			<Row>
				<Configure hitsPerPage={hitsPerPage || 20} />
				<CustomHits />
				<Paginator
					showNext={true}
					showPrevious={true}
					showFirst={true}
					showLast={true}
					className="ant-paginator"
				/>
			</Row>
		);
	}
}

export default Hits;
